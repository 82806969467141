.footer_bar {
  // position: fixed;
  // z-index: 999;
  background: rgba(84, 93, 110, 1);
  min-width: 1200px;
  width: 100%;
  //height: 185px;
  //margin-top: 106px;

  .info {
    width: 100%;
    padding-top: 26px;


    .con_01 {
      width: 1200px;
      height: 82px;
      margin: 0 auto;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: auto auto auto auto auto auto auto;


      .brand {
        position: relative;
        opacity: 0.4;

        img {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          height: 46px;
        }
      }
    }

    .con_02 {
      color: rgba(255, 255, 255, 1);
      padding-top: 25px;
      width: 1200px;
      height: 83px;
      margin: 0 auto;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }

    .con_03 {
      width: 420px;
      margin: 0 auto;
      margin-top: 11px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: auto auto;


      .img_con {
        text-align: center;
        display: block;
        position: relative;
        margin-bottom: 8px;

        img {
          display: block;
          width: 41px;
          margin: auto;
        }

        span {
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
        }
      }
    }
  }

  .rights {
    color: rgba(255, 255, 255, 1);
    margin-top: 38px;
    padding-bottom: 22px;
    width: 100%;
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
}