.vipTopMenu {
  .menu_bar {
    .menuUl {
      li {
        color: rgba(122, 156, 249, 1) !important;
      }
    }

    .right_con {
      color: rgba(122, 156, 249, 1) !important;

      a {
        color: rgba(122, 156, 249, 1) !important;
      }
    }
  }
}
