.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

.contain {
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  height: auto;
  background: rgb(229, 236, 241) !important;
  background-size: cover;

  .topLine {
    background: linear-gradient(-90deg, #345ED3, #7A9CF9);
    height: 98px;
  }

  .content {
    background: rgb(254, 254, 255);
    position: relative;
    z-index: 99;
    .setW-H(1200px, 1340px);
    margin: 22px auto 25px auto;
    padding-bottom: 50px;
    box-shadow: #c1c0c0 0px 0px 20px;
    overflow: hidden;
    border-radius: 10px;

    .line {
      .banner1 {
        .setW-H(487px, 462px);
        float: left;
        margin: 113px 0 0 88px;

        .banner_con{
          position: relative;
          .setW-H(487px, 462px);
          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .mark {
        margin: 93px 67px 0 0;
        width: 505px;
        float: right;

        .title {
          font-size: 40px;
          text-align: center;
          color: rgba(51, 51, 51, 1);
        }

        .subtitle {
          color: rgba(51, 51, 51, 1);
          font-size: 20px;
          width: 350px;
          margin: 31px auto 22px auto;
          position: relative;
          text-align: center;
          font-weight: bold;

          .iconRight {
            top: 8px;
            right: 0;
            position: absolute;
            width: 14px;
            height: 14px;
            background: linear-gradient(-90deg, #7A9CF9, #466fd4);
            transform: rotate(45deg);
          }

          .iconLeft {
            top: 8px;
            position: absolute;
            width: 14px;
            height: 14px;
            left: 0;
            background: linear-gradient(-90deg, #7A9CF9, #466fd4);
            transform: rotate(45deg);
          }
        }

        .subtitle2 {
          font-size: 14px;
          text-align: center;
          color: rgba(75, 75, 75, 1);
        }

        .subline {
          .qr {
            width: 175px;
            height: 175px;
            position: relative;
            padding: 17px;
            top: 25px;
            float: left;
            box-shadow: #c1c0c0 0px 0px 20px;
            border-radius: 10px;

            b {
              text-align: center;
              display: block;
              margin: 0 auto;
              margin-top:50px;
            }

            img {
              .setW-H(170px, 170px);
            }

            .rqtitle {
              color: rgba(153, 153, 153, 1);
              position: absolute;
              width: 180px;
              text-align: center;
              font-size: 14px;
              bottom: -60px;
              left: 0px;

              span {
                font-size: 12px;
              }
            }
          }

          .h5 {
            float: right;
            position: relative;

            img {
              .setW-H(182px, 210px);
            }

            .copybut {
              position: absolute;
              .setW-H(108px, 30px);
              background: linear-gradient(-90deg, #345ED3, #7A9CF9);
              border-radius: 5px;
              color: #fff;
              line-height: 30px;
              text-align: center;
              bottom: -95px;
              left: 39px;
              cursor: pointer;
            }

            .h5title {
              position: absolute;
              color: rgba(153, 153, 153, 1);
              width: 180px;
              text-align: center;
              font-size: 14px;
              bottom: -51px;
              left: -4px;

              span {
                color: rgba(105, 154, 235, 1);
              }
            }
          }
        }
      }
    }

    .Line2 {
      .banner1 {
        float: right;
        margin: 215px 67px 0 0;
      }

      .mark {
        margin: 214px 0 0 118px;
        float: left;

        .subline {
          .qr {
            width: 175px;
            height: 175px;
            float: none;
            margin: 30px auto 0 auto;

            b {
              text-align: center;
              display: block;
              margin: 0 auto;
              margin-top:50px;
            }
          }
        }
      }
    }

  }

}
