.c {
  clear: both;
}

.homeapp {
  font: "微软雅黑", "黑体";

  .appTitle {
    font-size: 36px;
    font-weight: bold;
    color: #695C94;
    text-align: center;
    padding: 54px 0 30px 0;
  }

  .appLine {
    position: relative;
    height: 544px;

    .appTitleSubTitle {
      width: 518px;
      height: 104px;
      font-size: 14px;
      font-weight: 400;
      color: #768CBE;
      line-height: 30px;
      position: absolute;
      left: 0px;
      top: 51px;
    }


    .appH5Box {
      position: absolute;
      top: 220px;
      left: 250px;
      width: 180px;
      height: 180px;
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px 13px 32px 0px rgba(23, 76, 145, 0.11);
      border-radius: 10px;

      .appH5{
        margin: 15px 0 0 15px;
        line-height: 150px;
        width: 150px;
        height: 150px;
        position: relative;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 38px 0 0 38px;

        img {
          display: block;
          width: 72px;
          height: 82px;
        }
      }

      .h5txt {
        top: 180px;
        left: 20px;
        position: absolute;
        line-height: 12px !important;
        font-size: 14px;
        font-weight: 400;
        color: #6A7391;
        text-align: center;

        span {
          width: 118px;
          height: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #8B9BAA;
        }
      }

      .appH5txt {
        color: #999999;
        position: absolute;
        left: 0px;
        bottom: -56px;
        width: 200px;
        height: 45px;
        overflow: hidden;
        text-align: center;
        font-size: 14px;

        a {
          font-size: 14px;
          color: rgba(105, 154, 235, 1);
        }
      }

      .appH5linkBut {
        cursor: pointer;
        position: absolute;
        font-size: 12px;
        text-align: center;
        line-height: 24px;
        width: 68px;
        height: 24px;
        border: 1px solid #699AEB;
        bottom: -90px;
        left: 61px;
      }
    }

    .appQrBox {
      position: absolute;
      top: 220px;
      left: 0px;
      width: 180px;
      height: 180px;
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px 13px 32px 0px rgba(23, 76, 145, 0.11);
      border-radius: 10px;

      .appQr {
        margin: 15px 0 0 15px;
        line-height: 150px;
        width: 150px;
        height: 150px;
        position: relative;
        background: #FFFFFF;
        border-radius: 10px;

        img {
          display: block;
          width: 124px;
          height: 125px;
        }

        .appQrtxt {
          top: 183px;
          left: 20px;
          position: absolute;
          line-height: 12px !important;
          font-size: 14px;
          font-weight: 400;
          color: #6A7391;
          text-align: center;

          span {
            width: 118px;
            height: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #8B9BAA;
          }
        }
      }

    }

    .appMesi {
      position: absolute;
      width: 787px;
      height: 630px;
      right: 0px;

      img {
        width: 100%;
        height: auto;
      }
    }

  }
}
