.ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split {
  box-shadow: none;
}

.ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-input-affix-wrapper-focused, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background: transparent;
  border: none;
  box-shadow: none;
}

.ant-row {
  display: inline-block;
  background: transparent;
}

.ant-form-item {
  //width: 320px;
  background: transparent;
  position: relative;
  margin-bottom: 0;
}

.ant-input-affix-wrapper {
  padding: 0;
  background: transparent;
  border: none;
}

.ant-input-suffix {
  margin-left: 0;
  margin-top: 11px;
  width: 24px;
  height: 24px;

  svg {
    color: rgb(199, 203, 225.0);
    width: 24px;
    height: 24px;
  }
}

.baseInfoShowPwd {
  .ant-input-suffix {
    top: -5px;
    right: 10px;
    position: absolute;
    width: 20px !important;
    height: 20px !important;

    svg {
      color: rgb(199, 203, 225.0);
      width: 20px !important;
      height: 20px !important;
    }
  }
}


.ant-form-item-explain, .ant-form-item-extra {
  height: 0px !important;
  min-height: 0px !important;
}

:focus {
  outline: none;
}

.ant-message-notice-content{

}

.ant-modal {
  top: 200px;
}