@import "../util/cssImgRemoteSrc";

.transfer_normal{
  .refresh_btn_rotate {
    position: relative;
    transform: rotate(0deg);
    transition: all .5s;
  }

  .out {
    float: left;
    display: block;
    .ant-select-selector {
      float: left;
      display: block;
      position: relative;
      text-align: center;
      font-size: 14px;
      color: #999999;
      width: 130px !important;
      height: 45px !important;
      border: none !important;
      cursor: pointer !important;
      line-height: 45px;
      background: url("../../images/usercenter/lump_out.png") no-repeat center;
      .getBgSrc("/moneybag/lump_out.png", center);
      background-size: cover;
    }

    .ant-select-arrow {
      display: none;
    }

    .ant-select-selection-item{
      padding:0!important;
    }
  }

  .in {
    float: left;
    display: block;
    .ant-select-selector {
      float: left;
      display: block;
      position: relative;
      text-align: center;
      font-size: 14px;
      color: #333333;
      width: 130px !important;
      height: 45px !important;
      border: none !important;
      cursor: pointer !important;
      line-height: 45px;
      background: url("../../images/usercenter/lump_in.png") no-repeat center;
      .getBgSrc("/moneybag/lump_in.png", center);
      background-size: cover;
    }

    .ant-select-arrow {
      display: none;
    }

    .ant-select-selection-item{
      padding:0!important;
    }
  }
}
