.c {
  clear: both;
}

.topMenu {
  position: fixed;
  min-width: 1200px;
  width: 100%;
  height: auto;
  top: 0;
  z-index: 1000;

  .info {
    background: rgba(137, 144, 185, 1);
    width: 100%;
    height: 28px;
    z-index: 9;
    position: relative;

    .con {
      width: 1185px;
      height: 28px;
      margin: 0 auto;
      line-height: 28px;
      font-size: 14px;
      color: rgba(137, 144, 185, 1);
      position: relative;
      background: url("../../images/icon/topBrocastIcon.png") 0px 6px no-repeat;
      background-size: 16px 17px;

      .scroText {
        //height: 28px;
        width: 1160px !important;
        overflow: hidden;
        white-space: nowrap;
        text-indent: 24px;
        float: right;

        span {
          font-size: 12px;
          color: #FFFFFF;
        }
      }

      .left_time {
        position: absolute;
        left: -170px;
        font-size: 12px;
        color: rgba(227, 233, 248, 1);
      }
    }
  }

  .menu_bar_blur {
    z-index: 1;
    top: 28px;
    left: 0;
    right: 0;
    margin: auto;
    height: 70px;
    width: auto;
    position: fixed;
    background: rgba(244, 246, 255, 1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .1);
  }

  .menu_bar {
    left: 0;
    right: 0;
    z-index: 9;
    top: 28px;
    position: fixed;
    margin: auto;
    width: 1185px;

    .logo {
      float: left;
      padding-top: 14px;

      img {
        width: 214px;
        height: 37px;
      }
    }

    .menuUl {
      margin: 0;
      list-style: none;
      float: left;

      li {
        cursor: pointer;
        padding: 23px 0 8px 0;
        margin-left: 41px;
        float: left;
        font-size: 15px;
        font-weight: bold;
        color: #516FB1;
        position: relative;

        .arrow {
          background: url("../../images/public/topmenuarrow.png") no-repeat;
          background-size: cover;
          position: absolute;
          width: 9px;
          height: 5px;
          top: 32px;
          right: -15px;
        }

        a {
          color: #516FB1;
        }
      }

      .actLi {
        border-bottom: 3px solid rgba(106, 74, 212, 1);
        border-radius: 2px;
        color: rgba(106, 74, 212, 1);
      }
    }

    .right_con {
      margin: 22px 14px 0 0;
      position: relative;
      float: right;
      color: rgba(81, 111, 177, 1);
      font-size: 14px;
      font-weight: bold;

      .loginReg {
        float: right;

        span {
          padding-left: 25px;
          cursor: pointer;

          a {
            font-size: 16px;
            font-weight: 400;
            color: #3E4660;
          }
        }
      }

      .loginOut {
        position: absolute;
        right: 10px;
        top: 0px;
        width: 50px;

        span {
          padding-left: 20px;
          cursor: pointer;

          a {
            font-size: 16px;
            font-weight: 400;
            color: #3E4660;
          }
        }
      }

      /*span:nth-child(4) {
        margin-right: 70px;
      }*/

      .headimg {
        position: absolute;
        top: -10px;
        right: 60px;
        width: 35.7px;
        height: 35.7px;
        border-radius: 50px;
        overflow: hidden;

        img {
          width: 35.7px;
          height: 35.7px;
        }
      }
    }
  }
}
