.cards {
  font: "微软雅黑", "黑体";
  padding-top: 20px;

  .attentionPop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: auto;
    background: rgba(0, 0, 0, .7);
    z-index: 10;

    .con {
      position: absolute;
      top: 20%;
      right: 0;
      left: 0;
      width: 490px;
      height: 400px;
      margin: auto;
      background: white;
      border-radius: 10px;
      overflow: hidden;

      .title_bar {
        position: relative;
        height: 46px;
        line-height: 46px;
        background: linear-gradient(90deg, #749cf9, #345ed3);
        border-radius: 8px 8px 0 0;
        padding-left: 24px;
        font-size: 16px;
        color: #ffffff;
        font-weight: normal;
        margin: 0;

        .close {
          position: absolute;
          width: 30px;
          height: 30px;
          top: 0;
          right: 5px;
          cursor: pointer;
        }
      }

      .attention_detail {
        height: 274px;
        padding: 25px;
        overflow-y: auto;
        overflow-x: hidden;

        .attention_title {
          text-align: left;
          margin-bottom: 10px;
          position: relative;
          font-size: 20px;
          color: black;
          line-height: 16px;
          font-weight: bold;
          word-break: break-all;
        }

        .attention_time {
          margin-bottom: 5px;
          color: black;
          line-height: 16px;
          font-size: 12px;
          word-break: break-all;
          white-space: pre-wrap;
          text-align: justify;
        }

        .attention_des {
          color: #999;
          line-height: 26px;
          font-size: 16px;
          word-break: break-all;
          white-space: pre-wrap;
          text-align: justify;
          margin-bottom: 30px;
        }
      }

      .btn_sure {
        width: 100%;
        height: 80px;
        margin: 0;
        position: relative;

        span {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 120px;
          height: 32px;
          line-height: 32px;
          border-radius: 6px;
          background: linear-gradient(90deg, #749cf9, #345ed3);
          font-size: 16px;
          letter-spacing: 2px;
          color: #fff;
          font-weight: 500;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  .card {
    position: relative;
    background: url("../../../images/public/noticeBg.png");
    float: left;
    background-size: cover;
    width: 590px;
    height: 145px;
    box-shadow: 0px 8px 21px 0px rgba(60, 92, 135, 0.2);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 180px auto;
    .title {
      top: 20px;
      left: 182px;
      position: absolute;
      font-size: 24px;
      font-weight: bold;
      color: #695C94;
    }

    .sunTitle {
      position: absolute;
      top: 72px;
      left: 182px;
      font-size: 14px;
      font-weight: 400;
      color: #8B9BAA;
      width: 391px;

      span{
        font-weight: bold;
        color: #CC3333;
      }
    }
    .imgC{
      position: relative;
      width: 180px;
      height: 137px;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .card:nth-child(1) {
    float: left;
  }

  .card:nth-child(1) {
    float: right;
  }
}
