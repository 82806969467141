@import "../util/cssImgRemoteSrc";

.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

.isShow {
  display: block;
}

.isHide {
  display: none;
}

.titleLine {
  position: relative;
  color: #3D3D3D;
  font-size: 16px;
  padding: 10px 0 10px 33px;
  font-weight: bold;
}

.contain_transfer {

  .btn_refresh {
    position: relative;
    left: 15px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    opacity: .7;
    text-indent: 27px;
    color: #666666;
    width: 85px;
    height: 25px;
    line-height: 24px;
    border: 1px solid #EDEDEE;
    border-radius: 13px;
    margin-top: 15px;
    cursor: pointer;

    .refresh_btn_rotate {
      display: inline-block;
      position: relative;
      top: -11px;
      left: -20px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: auto;
      }
    }
  }

  .content_transfer {
    position: relative;
    //background: url("../../images/moneybag/bg_white.png") no-repeat;
    //.getBgSrc("/moneybag/bg_white.png");
    //background-size: contain;
    padding-top: 20px;

    .money_bag_lump {
      width: 686px;
      height: 95px;
      margin: 0px auto;
      margin-bottom: 10px;
      display: grid;
      grid-template-columns: auto auto auto;

      .moneybag_kind:last-child {
        margin-left: 18px;
      }

      .moneybag_kind:nth-child(2) {
        margin-left: 20px;
      }

      .moneybag_kind {
        overflow-y: hidden;
        position: relative;
        width: 215px;
        height: 95px;
        background: #FFFFFF;
        border: 1px solid #CFDAF8;
        border-radius: 5px;
        box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.05);

        .title {
          display: block;
          width: 185px;
          margin: 0px auto;
          font-size: 16px;
          font-weight: 500;
          color: #666666;
          position: unset !important;
          text-align: left;
        }

        i {
          display: block;
          width: 185px;
          line-height: 53px;
          margin: 0px auto;
          font-size: 24px;
          color: #333333;
          font-style: unset;
          border-top: 1px solid #EDEEF0;
        }

        .btn_return {
          position: absolute;
          right: 15px;
          bottom: 14px;
          text-align: center;
          color: white;
          font-size: 14px;
          font-weight: unset;
          opacity: .7;
          width: 46px;
          height: 25px;
          line-height: 25px;
          background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
          border-radius: 5px;
          cursor: pointer;

        }

        .btn_into {
          font-size: 24px;
          text-align: center;
          text-indent: 0px;
          color: #20C9DD;
          width: 200px;
          height: 42px;
          line-height: 0px;
          cursor: pointer;
          margin-top: 15px;
        }

        span {
          position: relative;
          display: inline-block;
          color: black;
          font-size: 25px;
          text-align: center;
          text-indent: 0px;
          border-right: 1px solid rgba(255, 255, 255, .15);
          height: 95px;
          line-height: 38px;
          top: 33px;
        }
      }
    }

    .withdraw_counter {
      font-size: 20px;
      color: #6c6c6c;
      text-align: left;
      text-indent: 70px;
      width: 900px;
      display: inline-block;
      margin-top: 20px;
      margin-left: -80px;
      transform: scale(.85);

      i {
        margin: auto 5px;
      }
    }

    .normal_free_lump {
      display: inline-block;
      margin-top: 40px;
      margin-bottom: 40px;
      font-size: 30px;
      text-indent: 60px;
      color: #6c6c6c;
    }

    .input_custom {
      position: relative;
      width: 610px;
      height: 60px;
      border-bottom: 2px solid rgba(255, 255, 255, .3);
      line-height: 60px;
      margin: 20px auto;
      padding-top: 7px;

      span {
        font-size: 30px;
        position: absolute;
        top: 13px;
        left: -2px;
      }

      input {
        position: relative;
        top: 0px;
        width: 410px;
        line-height: 44px;
        padding: 0;
        padding-left: 50px;
        background: transparent;
        border: none;
        border-radius: unset;
        box-shadow: none;
        font-size: 24px;

        &::placeholder {
          color: black !important;
          opacity: .8;
          font-size: 24px;
        }
      }
    }


    .money_ele {
      position: relative;
      width: 680px;
      height: auto;
      margin: 40px auto;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: auto auto auto;

      .money_custom {
        position: relative;
        width: 220px;
        height: 70px;

        input {
          color: #6c6c6c;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          border: 1px solid #ebebeb;
          border-radius: 10px;
          width: 150px;
          height: 70px;
          line-height: 70px;
        }

        i.input_des {
          color: #6c6c6c;
          text-align: center;
          font-size: 22px;
          line-height: 28px;
          position: relative;
          top: -60px;
          margin: 0 auto;
          width: 170px;
          left: -10px;
          transform: scale(.85);

          i {
            display: block;
            font-size: 18px;
            color: #9aa0ac;
          }
        }

        b {
          position: absolute;
          width: 100%;
          font-size: 24px;
          text-align: center;
          left: 0;
          right: 0;
          margin: auto;
          color: red;
          top: 180px;
        }
      }

      span {
        color: #6c6c6c;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        width: 220px;
        height: 70px;
        line-height: 70px;

        &.active {
          color: white;
          background: #20c9de;
          border: 1px solid #20c9de;
        }
      }
    }

    .moneybag_kind_ele {
      position: relative;
      width: 690px;
      height: auto;
      margin: 0 auto;
      //display: flex;
      //flex-direction: row;
      //flex-wrap: wrap;
      overflow: hidden;
      display: grid;
      grid-template-columns: auto auto auto auto auto auto;

      span {
        background: white;
        display: inline-block;
        position: relative;
        color: #666666;
        font-size: 14px;
        text-align: center;
        text-indent: 0px;
        border: 1px solid #EDEDEE;
        border-radius: 6px;
        width: 108px;
        height: 65px;
        line-height: 35px;
        padding-top: 5px;
        margin: 3px;
        margin-top: 14px;
        margin-bottom: 0px;
        flex: auto;

        i {
          position: relative;
          top: -10px;
          display: block;
          font-size: 16px;
          font-weight: 400;
          font-style: unset;
          color: #333333;
        }
      }
    }

    .moneybag_menus_lump {
      width: 100%;
      height: auto;
      background: white;

      .ele {
        border-bottom: 1px solid #ececec;
        margin: 10px auto;
        width: 650px;
        height: 105px;
        line-height: 60px;
        font-size: 26px;
        padding-left: 20px;
        padding-top: 25px;

        span {
          width: 60px;
          height: 60px;
          display: inline-block;
          margin-right: 40px;

          img {
            position: relative;
            top: 13px;
            width: 50px;
            height: auto;
          }
        }

        a {
          color: #666;
        }
      }
    }

    .deposit_pay_method {
      line-height: 60px;

      span {
        display: block;
        position: relative;
        text-align: left;
        font-size: 24px;
        font-weight: 400;
        line-height: 70px;
        width: 630px;
        height: 100px;
        margin-left: 45px;
        padding-left: 30px;
        border-bottom: 1px solid #ebebeb;

        .pay_method_img {
          width: 75px;
          height: 60px;
          position: relative;
          display: inline-block;
          margin: 0 auto;
          overflow: hidden;
          top: 20px;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 75px;
            height: auto;
          }
        }

        .pay_method_recommend {
          position: absolute;
          top: -28px;
          right: 29px;
          width: 31px;
          height: 23px;
        }

        .pay_method_name {
          display: inline-block;
          font-size: 28px;
          position: absolute;
          top: 30px;
          width: 190px;
          text-indent: 0px;
          text-align: left;
          line-height: 30px;
        }

        .pay_method_remark {
          display: inline-block;
          font-size: 20px;
          position: absolute;
          top: 17px;
          left: 300px;
          color: #9aa0ac;
          width: 300px;
          text-align: left;
          line-height: 30px;
        }
      }

      .active {
        color: #6ACEDA;
        //background: url('../../images/moneybag/icon_sure2.png') no-repeat;
        //.getBgSrc("/moneybag/icon_sure2.png", 600px 35px);
      }
    }
  }

  .moneybag_select_lump {
    position: relative;
    width: 686px;
    height: 80px;
    margin: 0 auto;
    font-size: 40px;
    font-weight: bold;
    color: #999999;

    .auto_transfer {

      margin-bottom: 15px;

      .normal_free_lump {
        width: auto;
        height: auto;
        padding: unset;
        background: unset;
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        color: #3D3D3D;
      }

      .btn_lump {
        position: absolute;
        top: 30px;
        left: 70px;
        display: block;
        width: 38px;
        height: 23px;
        background: unset;
        box-sizing: unset;
        cursor: pointer;

        .btn_normal {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 38px;
          height: 23px;
          background: unset;

          img {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 38px;
            height: 23px;
          }
        }

        .btn_free {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 38px;
          height: 23px;
          background: unset;

          img {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 38px;
            height: 23px;
          }
        }
      }
    }

    .arrow {
      float: left;
      display: block;
      position: relative;
      top: 12px;
      left: 0;
      background: url("../../images/usercenter/icon_transfer.png") no-repeat;
      .getBgSrc("/usercenter/icon_transfer.png");
      background-size: contain;
      width: 25px;
      height: 25px;
      margin: 0 10px;
    }
  }

  .content_amount {
    clear: both;
    width: 686px;
    height: auto;
    margin: 0 auto;

    .input_custom {
      position: relative;
      width: 620px;
      height: auto;
      border-bottom: 2px solid rgba(255, 255, 255, .3);
      line-height: 60px;
      padding-bottom: 50px;

      .input_custom_des {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        line-height: 23px;
        display: block;
        margin-bottom: 10px;
        margin-top: 70px;

        > i {
          color: #CC3333;
          font-style: normal;
        }
      }

      .input_custom_des2 {
        position: relative;
        top: -25px;
        left: 47px;
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        color: #CC3333;
        line-height: 23px;
      }

      span {
        color: #666666;
        font-size: 14px;
        position: relative;
        top: -14px;
        left: 0px;
      }

      input {
        display: inline-block;
        position: unset !important;
        width: 169px;
        height: 29px;
        background: #FFFFFF;
        border: 1px solid #EDEDEE;
        border-radius: 5px;

        &::placeholder {
          color: #C3C3C3 !important;
          opacity: .8;
          font-size: 12px;
        }
      }
    }

    .all_amount {
      position: relative !important;
      display: inline-block;
      width: 76px;
      height: 29px;
      background: #FFFFFF;
      border: 1px solid #EDEDEE;
      border-radius: 5px;
      line-height: 29px;
      text-align: center;
      font-size: 14px!important;
      font-weight: 500;
      cursor: pointer;
      color: #333333;
      top: -14px !important;
      left: 10px !important;
    }

    .btn_deposit {
      display: block;
      color: white;
      text-align: center;
      font-size: 15px;
      width: 108px;
      height: 30px;
      line-height: 30px;
      background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
      border-radius: 5px;
      position: absolute;
      top: -30px;
      left: 300px;
      border: none;
      cursor: pointer;
    }
  }
}
