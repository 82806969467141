.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

.discount {
  .dataEmpty {
    font-size: 14px;
    text-align: center;
    padding-top: 100px;

    img {
      margin: 0 auto 15px auto;
      display: block;
      .setW-H(100px, 78px)
    }
  }
}
