#loginName .ant-input-suffix {
  position: relative;
  left: -25px;
  top:-3px;

  svg{
    width: 15px;
    height: auto;
  }
}

#signPhone .ant-input-suffix {
  position: relative;
  left: -25px;
  top:-3px;

  svg{
    width: 15px;
    height: auto;
  }
}

#loginPwd .ant-input-suffix {
  position: relative;
  left: -50px;
  top:-3px;

  svg{
    margin-right: 5px;
    width: 15px;
    height: auto;
  }
}

#signPwd .ant-input-suffix {
  position: relative;
  left: -50px;
  top:-3px;

  svg{
    margin-right: 5px;
    width: 15px;
    height: auto;
  }
}

#signRePwd .ant-input-suffix {
  position: relative;
  left: -50px;
  top:-3px;

  svg{
    margin-right: 5px;
    width: 15px;
    height: auto;
  }
}

#antivirus .ant-input-suffix {
  position: relative;
  left: -58px;
  top:-3px;

  svg{
    width: 15px;
    height: auto;
  }
}
