.container {
  //background: linear-gradient(0deg, rgba(0, 149, 239, 0.15), rgba(0, 190, 245, 0));
  width: 1100px;
  height: 100% !important;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto auto;

  .player {
    display: block;
    width: 578px;
    height: 514px;
    margin: 20px auto;
    margin-top: 200px;

    img {
      width: 578px;
      height: 514px;
    }
  }

  .info {
    position: relative;
    top: 350px;
    left: 0px;
    font-size: 24px;
    font-weight: 400;
    color: #4C5665;
    line-height: 38px;

    i {
      display: block;
      font-size: 14px;
      color: #8B9BAA;
      line-height: 38px;
      font-style: normal;
      margin-top: 21px;
    }
  }

  .reload_btn {
    width: 120px;
    height: 44px;
    line-height: 44px;
    background: linear-gradient(0deg, #DDF0FF, #FFFFFF);
    border: 1px solid;
    border-image: linear-gradient(0deg, #A1C7E6, #DFE8FE) 1 1;
    box-shadow: 0px 2px 5px 0px rgba(19, 55, 153, 0.11), 0px 2px 0px 0px #FFFFFF;
    border-radius: 6px;
    font-size: 15px;
    font-weight: bold;
    color: #757C94;
    text-align: center;
    margin-top: 28px;
    cursor: pointer;
  }

  .btn {
    display: block;
    background: #FF862B;
    color: white;
    font-size: 33px;
    text-align: center;
    width: 254px;
    height: 85px;
    background: linear-gradient(-90deg, #345ed3, #7a9cf9);
    border-radius: 18px;
    line-height: 80px;
    margin: 10px auto;
    font-weight: 500;
  }
}
