.swiper_box {
  margin-top: 98px;
  position: relative;
  height: 447px;
  overflow: hidden;
}

.swiper-container01 {
  .swiper-slide {
    width: 100% !important;
    height: auto;

    img {
      width: 1920px;
      height: 447px;
    }
  }
}

.swiper-pagination01 {
  width: 100% !important;
  position: absolute;
  bottom: 15px;

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: #000000;
    border-radius: 50%;
    margin-left: 10px;
    opacity: .4;
  }

  .swiper-pagination-bullet-active {
    width: 40px;
    height: 8px;
    background: #FFFFFF;
    border-radius: 4px;
    opacity: .9;
  }

}

