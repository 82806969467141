.contain {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto;
  background: rgba(0, 0, 0, .7);
  z-index: 1001;

  .con {
    position: absolute;
    top: 20%;
    right: 0;
    left: 0;
    width: 460px;
    height: 465px;
    border-radius: 10px;
    margin: auto;
    background: white;
    overflow: hidden;

    .title {
      width: 460px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      text-align: center;
    }

    .poster {
      background: url("../../images/newHome/bg_recharge_notice.png") no-repeat;
      background-size: cover;
      width: 460px;
      height: 240px;
    }

    .info {
      width: 460px;
      height: auto;
      color: #333333;
      text-align: center;
      font-size: 14px;
      font-weight: 400;

      i {
        color: #999999;
        font-style: normal;
        display: block;
        padding-top: 13px;
        margin-bottom: 3px;
      }

      span {
        display: block;
        font-size: 24px;
        font-weight: 400;
        color: #CC3333;
      }
    }

    .btn_sure {
      width: 256px;
      height: 40px;
      line-height: 40px;
      background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
      border-radius: 5px;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      color: #FEFEFE;
      margin: 0 auto;
      margin-top: 15px;
      cursor: pointer;
    }
  }
}
