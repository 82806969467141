.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

ul {
  list-style: none;
}


.contain {
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  height: auto;

  .topLine {
    background: rgb(244, 246, 252);
    height: 98px;
  }

  .content {
    background: url("../../images/public/fish_bg.png") no-repeat top center;
    background-size: cover;

    .shadowLine{
      width: 100%;
      height: 20px;
      background: linear-gradient(0deg, rgba(212, 217, 245, 0), rgba(177, 183, 218, 0.15));
      top: 99px;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
    }


    .con {
      padding: 53px 0 110px 0 !important;
      position: relative;
      width: 1200px;
      margin: auto;
    }


    .banner1 {
      .setW-H(580px, 660px);
      position: relative;
      cursor: pointer;
      float: left;
      margin-right: 40px;

      .banner_con{
        position: absolute;
        .setW-H(580px, 660px);
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .banner2 {
      .setW-H(580px, 310px);
      position: relative;
      cursor: pointer;
      float: left;
      margin-bottom: 40px;

      .banner_con{
        .setW-H(580px, 310px);
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .banner3 {
      .setW-H(270px, 310px);
      position: relative;
      cursor: pointer;
      float: left;
      margin-right: 40px;

      .banner_con{
        .setW-H(270px, 310px);
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .banner4 {
      .setW-H(270px, 310px);
      position: relative;
      cursor: pointer;
      float: left;

      .banner_con{
        .setW-H(270px, 310px);
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .platform {
      top: 30px;
      right: 0;
      position: absolute;
      float: right;
      padding: 0;

      li {
        cursor: pointer;
        float: left;
        .setW-H(128px, 50px);
        background: url("../../images/public/platformBg.png");
        background-size: cover;
        text-align: center;
        line-height: 44px;
        margin-left: 3px;
        font-size: 15px;
        font-weight: bold;
        color: #757C94;
      }

      .actli {
        background: url("../../images/public/platformBgAct.png");
        background-size: cover;
        color: #FFFFFF;
      }
    }

    .fontTitle {
      position: absolute;
      top: 233px;
      right: 319px;

      img {
        .setW-H(294px, 76px);
      }
    }

    .fontTitle1 {
      position: absolute;
      top: 233px;
      right: 284px;

      img {
        .setW-H(335px, 76px);
      }
    }

    .fontTitle2 {
      position: absolute;
      top: 233px;
      right: 340px;

      img {
        .setW-H(276px, 76px);
      }
    }

    .fontTitle3 {
      position: absolute;
      top: 233px;
      right: 323px;

      img {
        .setW-H(294px, 76px);
      }
    }


  }
}
