.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

.bg {
  background: black;
  position: fixed;
  background: url("../../images/login/loginBg_new.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  .login_bg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.service_lump {

  position: fixed;
  width: 158px;
  height: 60px;
  line-height: 60px;
  border-radius: 20px;
  background: white;
  top: 0;
  right: 20px;
  bottom: 0;
  margin: auto;
  font-size: 15px;
  font-weight: 400;
  color: #6B5C95;
  cursor: pointer;

  span {
    margin-left: 28px;
    color: #6B5C95;

    img {
      display: inline-block;
      width: 27px;
      height: auto;
      margin-right: 10px;
    }
  }
}

.bgForget {

  position: fixed;
  background: url("../../images/login/loginBg_new.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.topMenu {
  position: fixed;
  min-width: 1200px;
  width: 100%;
  height: auto;
  top: 0;
  z-index: 1000;

  .info {
    background: #6F849A;
    width: 100%;
    height: 34px;
    z-index: 9;
    position: relative;

    .con {
      width: 1240px;
      height: 34px;
      margin: 0 auto;
      line-height: 34px;
      font-size: 14px;
      color: #C0C0C0;

      .left_time {
        float: left;
        font-size: 12px;
        color: #fff;
      }
    }
  }
}

//忘记密码
.ForgetBox {
  position: fixed;
  width: 390px;
  height: 370px;
  border-radius: 30px;
  background: white;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;

  .logoTitleImage {
    display: block;
    cursor: pointer;
    width: 174px;
    height: 30px;
    margin: 44px auto;
  }

  .des {
    display: block;
    font-size: 15px;
    font-weight: 400;
    color: #C73C50;
    text-align: center;
    margin: 0 auto;
  }

  .forgetBut {
    position: absolute;
    border: none;
    top: 255px;
    left: 0;
    right: 0;
    margin: auto;
    width: 300px;
    height: 40px;
    background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
    border-radius: 20px;
    font-size: 14px;
    line-height: 0px;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }

  .signLoginLinks {
    margin: 0 auto;
    margin-top: 175px;
    width: 270px;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;

    span:first-child {
      text-align: left;
      height: 26px;
    }

    span:last-child {
      text-align: right;
      height: 26px;
    }

    a {
      color: #333333;

      i {
        color: #C73C50;
        font-style: normal;
        text-decoration: underline;
      }
    }
  }

  .forgetTitleImage {
    cursor: pointer;
    width: 161px;
    height: 40px;
    position: absolute;
    right: 137px;
    top: 33px;
  }

  .forgetSubTitle {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #666666;
    position: absolute;
    right: 0;
    left: 0;
    top: 191px;
    margin: auto;
  }
}

//登录
.loginBox {
  position: fixed;
  width: 390px;
  height: 470px;
  border-radius: 30px;
  background: white;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;

  .loginImage {
    position: absolute;
    width: 536px;
    height: 601px;
    top: -60px;
    left: -106px;
  }

  .logoTitleImage {
    display: block;
    cursor: pointer;
    width: 174px;
    height: 30px;
    margin: 44px auto;
  }

  .des {
    display: block;
    font-size: 15px;
    font-weight: 400;
    color: #C73C50;
    text-align: center;
    margin: 0 auto;
  }

  .loginTable {
    position: absolute;
    top: 170px;
    right: 45px;

    .inputLineLogin {
      margin-bottom: 40px;
    }

    .inputLine {
      height: 45px;
      margin-bottom: 17px;
      position: relative;

      .inputBg {
        width: 300px;
        height: 40px;
        background: #F6F6F6;
        border: 1px solid #DEDEDE;
        border-radius: 20px;
      }

      .checkCode {
        z-index: 99999;
        cursor: pointer;
        position: absolute;
        right: 40px;
        top: 10px;
        width: 100px;
      }

      input {
        float: left;
        width: 265px;
        line-height: 40px;
        padding: 0;
        background: transparent !important;
        border: none;
        border-radius: unset;
        box-shadow: none;
        color: rgba(145, 145, 145, 1);
        margin-top: 0px;
        text-align: left;
        text-indent: 10px;
        margin-left: 30px;
      }

      .inputIcon {
        z-index: 1;
        position: absolute;
        top: 1px;
        left: 10px;

        img {
          width: 19px;
          height: 38px;
        }
      }
    }

    .inputLineAntivirus {
      height: 45px;
      margin-bottom: 17px;
      position: relative;

      .inputBg {
        width: 190px;
        height: 40px;
        background: #F6F6F6;
        border: 1px solid #DEDEDE;
        border-radius: 20px;
      }

      .checkCode {
        z-index: 99999;
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 5px;
        width: 100px;
      }

      input {
        float: left;
        width: 190px;
        line-height: 40px;
        padding: 0;
        background: transparent !important;
        border: none;
        border-radius: unset;
        box-shadow: none;
        color: rgba(145, 145, 145, 1);
        margin-top: 0px;
        text-align: left;
        text-indent: 10px;
        margin-left: 30px;
      }

      .inputIcon {
        z-index: 1;
        position: absolute;
        top: 1px;
        left: 10px;

        img {
          width: 19px;
          height: 38px;
        }
      }
    }

    .inputStyleMsg {
      color: rgba(248, 107, 153, 1);
      font-size: 12px;
      //margin: 9px 0 20px 0;
      text-indent: 30px;
    }

    .loginBut {
      position: absolute;
      border: none;
      left: 0;
      right: 0;
      width: 300px;
      height: 40px;
      background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
      border-radius: 20px;
      font-size: 14px;
      line-height: 0px;
      text-align: center;
      color: #fff;
      cursor: pointer;
    }

    .forget_lump {
      display: grid;
      grid-template-columns: 50% 50%;

      .rememberPwd {
        color: #666666;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        text-indent: 10px;

        .remember_password_false {
          position: relative;
          top: 5px;
          width: 20px;
          height: 20px;
          background: #F6F6F6;
          border: 1px solid #DEDEDE;
          border-radius: 3px;
          cursor: pointer;
          margin-right: 7px;
        }

        .remember_password_true {
          position: relative;
          top: 5px;
          width: 20px;
          height: 20px;
          border-radius: 3px;
          background: url("../../images/login/remember_pwd.png") no-repeat center;
          background-size: cover;
          cursor: pointer;
          margin-right: 7px;
        }
      }

      .forgetPwd {
        color: #666666;
        font-size: 14px;
        font-weight: 400;
        text-align: right;

        a {
          color: #666666;
        }
      }
    }

    .signLinks {
      margin: 0 auto;
      margin-top: 26px;
      width: 270px;
      font-size: 14px;
      font-weight: 400;
      padding-top: 26px;
      position: relative;
      display: grid;
      grid-template-columns: 50% 50%;

      span:first-child {
        text-align: left;
        height: 26px;
      }

      span:last-child {
        text-align: right;
        height: 26px;
      }

      a {
        color: #333333;

        i {
          color: #C73C50;
          font-style: normal;
          text-decoration: underline;
        }
      }
    }

    .loginLinks {
      margin-top: 29px;
      width: auto;
      font-size: 16px;
      font-weight: bold;
      text-indent: 30px;
      text-align: center;
      color: #3997f7;
      padding-top: 0px;
      position: relative;

      span {
        position: absolute;
        width: 2px;
        height: 26px;
        background: linear-gradient(-90deg, #3997F7, #69B0F9);
      }

      a {
        color: #333333;
      }
    }
  }
}

//注册
.signBox {
  position: fixed;
  width: 390px;
  height: 590px;
  border-radius: 30px;
  background: white;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;

  .loginImage {
    position: absolute;
    width: 536px;
    height: 601px;
    top: -60px;
    left: -106px;
  }

  .logoTitleImage {
    display: block;
    cursor: pointer;
    width: 174px;
    height: 30px;
    margin: 44px auto;
  }

  .des {
    display: block;
    font-size: 15px;
    font-weight: 400;
    color: #C73C50;
    text-align: center;
    margin: 0 auto;
  }

  .loginTable {
    position: absolute;
    top: 170px;
    right: 45px;

    .inputLineLogin {
      margin-bottom: 40px;
    }

    .inputLine {
      height: 45px;
      margin-bottom: 17px;
      position: relative;

      .inputBg {
        width: 300px;
        height: 40px;
        background: #F6F6F6;
        border: 1px solid #DEDEDE;
        border-radius: 20px;
      }

      .checkCode {
        z-index: 99999;
        cursor: pointer;
        position: absolute;
        right: 40px;
        top: 10px;
        width: 100px;
      }

      input {
        float: left;
        width: 265px;
        line-height: 40px;
        padding: 0;
        background: transparent !important;
        border: none;
        border-radius: unset;
        box-shadow: none;
        color: rgba(145, 145, 145, 1);
        margin-top: 0px;
        text-align: left;
        text-indent: 10px;
        margin-left: 30px;
      }

      .inputIcon {
        z-index: 1;
        position: absolute;
        top: 1px;
        left: 10px;

        img {
          width: 19px;
          height: 38px;
        }
      }
    }

    .inputLineAntivirus {
      height: 45px;
      margin-bottom: 17px;
      position: relative;

      .inputBg {
        width: 190px;
        height: 40px;
        background: #F6F6F6;
        border: 1px solid #DEDEDE;
        border-radius: 20px;
      }

      .checkCode {
        z-index: 99999;
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 5px;
        width: 100px;
      }

      input {
        float: left;
        width: 190px;
        line-height: 40px;
        padding: 0;
        background: transparent !important;
        border: none;
        border-radius: unset;
        box-shadow: none;
        color: rgba(145, 145, 145, 1);
        margin-top: 0px;
        text-align: left;
        text-indent: 10px;
        margin-left: 30px;
      }

      .inputIcon {
        z-index: 1;
        position: absolute;
        top: 1px;
        left: 10px;

        img {
          width: 19px;
          height: 38px;
        }
      }
    }

    .inputStyleMsg {
      color: rgba(248, 107, 153, 1);
      font-size: 12px;
      //margin: 9px 0 20px 0;
      text-indent: 30px;
    }

    .loginBut {
      position: absolute;
      border: none;
      top: 0px;
      left: 0;
      right: 0;
      width: 300px;
      height: 40px;
      background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
      border-radius: 20px;
      font-size: 14px;
      line-height: 0px;
      text-align: center;
      color: #fff;
      cursor: pointer;
    }

    .forget_lump {
      display: grid;
      grid-template-columns: 50% 50%;

      .rememberPwd {
        position: absolute;
        color: #666666;
        font-size: 14px;
        font-weight: 400;
        left: 20px;
        top: 125px;
        text-indent: 33px;

        .remember_password_false {
          position: absolute;
          top: 1px;
          left: 2px;
          width: 20px;
          height: 20px;
          background: #F6F6F6;
          border: 1px solid #DEDEDE;
          border-radius: 3px;
          cursor: pointer;
        }

        .remember_password_true {
          position: absolute;
          top: 1px;
          left: 2px;
          width: 20px;
          height: 20px;
          border-radius: 3px;
          background: url("../../images/login/remember_pwd.png") no-repeat center;
          background-size: cover;
          cursor: pointer;
        }
      }

      .forgetPwd {
        position: absolute;
        color: #666666;
        font-size: 14px;
        font-weight: 400;
        right: 20px;
        top: 125px;

        a {
          color: #666666;
        }
      }
    }

    .signLinks {
      margin: 0 auto;
      margin-top: 14px;
      width: 270px;
      font-size: 14px;
      font-weight: 400;
      padding-top: 14px;
      position: relative;
      display: grid;
      grid-template-columns: 50% 50%;

      span:first-child {
        text-align: left;
        height: 26px;
      }

      span:last-child {
        text-align: right;
        height: 26px;
      }

      a {
        color: #333333;

        i {
          color: #C73C50;
          font-style: normal;
          text-decoration: underline;
        }
      }
    }

    .loginLinks {
      margin-top: 29px;
      width: auto;
      font-size: 16px;
      font-weight: bold;
      text-indent: 30px;
      text-align: center;
      color: #3997f7;
      padding-top: 0px;
      position: relative;

      span {
        position: absolute;
        width: 2px;
        height: 26px;
        background: linear-gradient(-90deg, #3997F7, #69B0F9);
      }

      a {
        color: #333333;
      }
    }
  }
}
