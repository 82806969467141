.c {
  clear: both;
}

.setW-H(@w, @h) {
  width: @w;
  height: @h;
}

.homegames {
  font: "微软雅黑", "黑体";

  .appTitle {
    font-size: 36px;
    font-weight: bold;
    color: #695C94;
    text-align: center;
    padding: 54px 0 20px 0;
  }


  .appTitleSubTitle {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #768CBE;
    line-height: 30px;
    margin-bottom: 30px;
  }

  .gamesLine {
    position: relative;
    width: 1200px;
    height: 500px;
    margin: auto;
    background: url("../../../images/newHome/gameLineBg.png");
    background-size: 1200px 500px;

    div {
      cursor: pointer;
    }

    .gamePlant1 {
      position: absolute;
      //background: url("../../../images/newHome/game1.png");
      //background-size: 410px 471px;
      top: 15px;
      left: 15px;
      .setW-H(410px, 471px);
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      padding-top: 27px;
      padding-left: 24px;
      line-height: 28px;

      .game1_con{
        position: absolute;
        top: 0px;
        left: 0px;
        .setW-H(410px, 471px);

        img{
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      span {
        display: block;
        font-size: 18px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.2);
        text-transform: uppercase;
      }
    }

    .gamePlant1:hover {
      background: url("../../../images/newHome/game1Act.png") no-repeat;
      background-size: 410px 469px;

      .game1_con{
        display: none;
      }
    }

    .gamePlant2 {
      position: absolute;
      //background: url("../../../images/newHome/game2.png");
      .setW-H(473px, 151px);
      background-size: 473px 151px;
      top: 15px;
      left: 391px;
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      padding-top: 27px;
      padding-left: 64px;
      line-height: 28px;

      .game2_con{
        position: absolute;
        top: 0px;
        left: 0px;
        .setW-H(473px, 151px);

        img{
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.2);
        text-transform: uppercase;
      }
    }

    .gamePlant2:hover {
      background: url("../../../images/newHome/game2Act.png") no-repeat;
      background-size: 473px 149px;

      .game2_con{
        display: none;
      }
    }

    .gamePlant3 {
      position: absolute;
      //background: url("../../../images/newHome/game3.png");
      .setW-H(354px, 150px);
      background-size: 354px 150px;
      top: 15px;
      left: 831px;
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      padding-top: 27px;
      padding-left: 64px;
      line-height: 28px;

      .game3_con{
        position: absolute;
        top: 0px;
        left: 0px;
        .setW-H(354px, 150px);

        img{
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.2);
        text-transform: uppercase;
      }
    }

    .gamePlant3:hover {
      background: url("../../../images/newHome/game3Act.png") no-repeat;
      background-size: 354px 149px;

      .game3_con{
        display: none;
      }
    }

    .gamePlant4 {
      position: absolute;
      //background: url("../../../images/newHome/game4.png");
      .setW-H(350px, 150px);
      background-size: 350px 150px;
      top: 175px;
      left: 335px;
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      padding-top: 27px;
      padding-left: 64px;
      line-height: 28px;

      .game4_con{
        position: absolute;
        top: 0px;
        left: 0px;
        .setW-H(350px, 150px);

        img{
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.2);
        text-transform: uppercase;
      }
    }

    .gamePlant4:hover {
      background: url("../../../images/newHome/game4Act.png") no-repeat;
      background-size: 350px 145px;

      .game4_con{
        display: none;
      }
    }

    .gamePlant5 {
      position: absolute;
      //background: url("../../../images/newHome/game5.png");
      .setW-H(523px, 150px);
      background-size: 523px 150px;
      top: 175px;
      left: 659px;
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      padding-top: 27px;
      padding-left: 64px;
      line-height: 28px;

      .game5_con{
        position: absolute;
        top: 0px;
        left: 0px;
        .setW-H(523px, 150px);

        img{
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.2);
        text-transform: uppercase;
      }
    }

    .gamePlant5:hover {
      background: url("../../../images/newHome/game5Act.png") no-repeat;
      background-size: 523px 150px;

      .game5_con{
        display: none;
      }
    }

    .gamePlant6 {
      position: absolute;
      //background: url("../../../images/newHome/game6.png");
      .setW-H(456px, 150px);
      background-size: 456px 150px;
      top: 335px;
      left: 280px;
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      padding-top: 27px;
      padding-left: 64px;
      line-height: 28px;

      .game6_con{
        position: absolute;
        top: 0px;
        left: 0px;
        .setW-H(456px, 150px);

        img{
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.2);
        text-transform: uppercase;
      }
    }

    .gamePlant6:hover {
      background: url("../../../images/newHome/game6Act.png") no-repeat;
      background-size: 456px 150px;

      .game6_con{
        display: none;
      }
    }

    .gamePlant7 {
      position: absolute;
      //background: url("../../../images/newHome/game7.png");
      .setW-H(484px, 150px);
      background-size: 484px 150px;
      top: 335px;
      left: 701px;
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      padding-top: 27px;
      padding-left: 64px;
      line-height: 28px;

      .game7_con{
        position: absolute;
        top: 0px;
        left: 0px;
        .setW-H(484px, 150px);

        img{
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.2);
        text-transform: uppercase;
      }
    }

    .gamePlant7:hover {
      background: url("../../../images/newHome/game7Act.png") no-repeat;
      background-size: 484px 150px;

      .game7_con{
        display: none;
      }
    }
  }
}
