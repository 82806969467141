
#scroll_div {
  height: 80px;
  overflow: hidden;
  white-space: nowrap;
  width: 670px;
  text-align: center;
  cursor: pointer;

  #scroll_begin, #scroll_end {
    display: inline;
  }
}
