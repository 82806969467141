.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

.bankCard {
  .tipLine {
    color: rgba(102, 102, 102, 1);
    font-size: 15px;
    padding: 10px 0 0 33px;
  }

  .bankFrame {
    padding: 22px 0 0 33px;

    .bankBox {
      cursor: pointer;
      .setW-H(336px, 160px);
      float: left;
      margin: 0 15px 15px 0;
      background: url("../../images/usercenter/bankbg.png") no-repeat center center;
      background-size: cover;
      border-radius: 12px;
      box-shadow: #c2c0c0 0px 3px 19px;

      .bankname {
        margin: 30px 0 0 27px;
        font-size: 15px;
        color: rgba(255, 255, 255, 1);
      }

      .bankNum {
        letter-spacing: 1px;
        color: rgba(255, 255, 255, 1);
        margin: 25px 0 0 27px;
        font-size: 21px;
        font-weight: bold;
      }
    }

    .bankBoxAct {
      background: url("../../images/usercenter/bankbgAct.png") no-repeat center center;
      background-size: cover;
      margin-right: 16px;
    }

    .bankBoxEmpty {
      cursor: pointer;
      .setW-H(336px, 160px);
      background: url("../../images/usercenter/bankbgempty.png") no-repeat center center;
      background-size: 54px 54px;
      border: 1px solid #D1DFE9;
      border-radius: 10px;
      float: left;
    }
  }

}

