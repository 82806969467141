.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

.isShow {
  display: block;
}

.isHide {
  display: none;
}

.baseinfo {
  .payList {
    display: flex;
    width: 281px;
    list-style: none;
    justify-content: space-between;
    margin: 0 0 0 55px;

    li {
      cursor: pointer;
      position: relative;
      border-radius: 7px;
      border: 2px solid rgba(237, 237, 238, 1);
      .setW-H(80px, 95px);

      img {
        width: 25px;
        height: auto;
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        margin: 15px auto 0 auto;
      }

      .payname {
        font-size: 12px;
        color: rgba(51, 51, 51, 1);
        text-align: center;
        position: absolute;
        left: 0;
        top: 33px;
        width: 100%;
        text-align: center;
        position: absolute;
        left: 0;
        top: 50px;
        width: 100%;
      }

      .paynameMid {
        line-height: 300%;
      }
    }

    .activeLi {
      border: 2px solid rgba(143, 167, 233, 1);
      background: url("../../images/usercenter/iconPayCheck.png") no-repeat bottom right;
      background-size: 16px 16px;
    }
  }

  .payTip {
    position: relative;
    color: rgba(75, 75, 74, 1);
    font-size: 12px;
    margin-top: 18px;
    text-indent: 220px;

    img {
      left: 200px;
      top: 1px;
      position: absolute;
      .setW-H(16px, 16px);
    }
  }

  .stepLine {
    font-size: 14px;
    color: #000;
    font-weight: bold;
    position: relative;
    margin: 59px 0 0 55px;

    .numBg {
      .setW-H(18px, 18px);
      line-height: 18px;
      text-align: center;
      background: url("../../images/usercenter/setpBg.png") no-repeat;
      background-size: cover;
      font-size: 14px;
      color: #fff;
      position: absolute;
      left: -25px;
      top: 2px;
    }
  }

  .conLine {
    margin-top: 5px;
    position: relative;
    height: 30px;
    line-height: 30px;
    text-indent: 63px;

    .but {
      position: absolute;
      left: 240px;
      top: 0px;
      border-radius: 5px;
      width: 90px;
      height: 30px;
      text-indent: 0px;
      text-align: center;
      cursor: pointer;
      color: #fff;
      background: linear-gradient(-90deg, rgb(127, 159, 235), rgb(176, 196, 250));
    }
  }

  .formBox {
    .txtLine {
      position: relative;
      color: #4b4b4a;
      height: 22px;
      text-indent: 125px;
    }

    .inputLine {
      position: relative;
      color: #4b4b4a;
      margin-bottom: 25px;

      .title {
        float: left;
        .setW-H(111px, 32px);
        line-height: 30px;
        text-align: right;
        margin-right: 5px;
      }

      .inputBox {
        position: relative;
        float: left;

        input {
          text-indent: 10px;
          float: left;
          .setW-H(274px, 27px);
          line-height: 27px;
          padding: 0;
          //background: transparent;
          border: 1px solid rgba(237, 237, 237, 1);
          border-radius: 5px;
          box-shadow: none;
          color: #000;
          text-align: left;
          font-size: 12px;
          background: #fff;
          //text-indent: 10px;
        }
      }

      .inputMsg {
        color: #000 !important;
        height: 32px;
        line-height: 32px;
        width: 300px;
        float: left;
        margin-left: 16px;
      }

      .inputErr {
        color: red;
      }
    }

    .butCenter {
      margin: auto;
    }

    .butSubmit {
      position: absolute;
      right: -127px;
      top: 0px;
      font-size: 14px;
      border: none;
      border-radius: 5px;
      width: 108px;
      height: 30px;
      text-align: center;
      cursor: pointer;
      color: #fff;
      background: linear-gradient(-90deg, rgb(127, 159, 235), rgb(176, 196, 250));
    }
  }

  .tipLine {
    margin: 30px 0 24px 0;
    position: relative;
    color: rgba(250, 68, 136, 1);
    font-size: 12px;
    text-align: center;

    .icon {
      position: absolute;
      left: 105px;
      right: 0;
      background: url("../../images/usercenter/iconTip.png") no-repeat;
      .setW-H(16px, 18px);
      background-size: cover;
    }
  }
}
