.c {
  clear: both;
}

body {
  background: rgb(244, 245, 252);
}

.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

.mask {
  position: fixed;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.contain {
  background: none;

  .topLine {
    background: linear-gradient(-90deg, #345ED3, #7A9CF9);
    height: 98px;
  }

  .content {
    position: relative;
    margin: auto;
    width: 1200px;
    margin-top: 22px;
    margin-bottom: 42px;

    .rightBanner {
      .setW-H(197px, 690px);
      float: right;

      .rightBanner_img {
        position: relative;
        .setW-H(197px, 690px);

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .mainBox {
      background: #fff;
      width: 747px;
      min-height: 690px;
      height: auto;
      border-radius: 10px;
      box-shadow: rgb(224, 224, 224) 4px 0px 20px;
      display: inline-block;
      margin-left: 229px;

      .titleLine {
        position: relative;
        color: rgba(61, 61, 61, 1);
        font-size: 16px;
        padding: 22px 0 25px 33px;
        font-weight: bold;
      }
    }

  }
}

