.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

.contain {
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  height: auto;
  background: rgb(229, 236, 241) !important;
  background-size: cover;

  .attentionPop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: auto;
    background: rgba(0, 0, 0, .7);
    z-index: 1001;

    .con {
      position: absolute;
      top: 10%;
      right: 0;
      left: 0;
      width: 1000px;
      height: 600px;
      margin: auto;
      background: white;
      border-radius: 10px;
      overflow: hidden;

      .title_bar {
        position: relative;
        height: 46px;
        line-height: 46px;
        background: linear-gradient(90deg, #749cf9, #345ed3);
        border-radius: 8px 8px 0 0;
        padding-left: 24px;
        font-size: 16px;
        color: #ffffff;
        font-weight: normal;
        margin: 0;

        .close {
          position: absolute;
          width: 30px;
          height: 30px;
          top: 0;
          right: 5px;
          cursor: pointer;
        }
      }

      .attention_detail {
        height: 500px;
        width: 100%;
        padding: 10px 10px 0px 10px;
        overflow:auto !important;

        .attention_title {
          text-align: left;
          margin-bottom: 10px;
          position: relative;
          font-size: 20px;
          color: black;
          line-height: 16px;
          font-weight: bold;
          word-break: break-all;
        }

        .attention_time {
          margin-bottom: 5px;
          color: black;
          line-height: 16px;
          font-size: 12px;
          word-break: break-all;
          white-space: pre-wrap;
          text-align: justify;
        }

        .attention_des {
          color: #999;
          line-height: 26px;
          font-size: 16px;
          word-break: break-all;
          white-space: pre-wrap;
          text-align: justify;
          margin-bottom: 30px;
        }
      }

      .btn_sure {
        width: 100%;
        height: 50px;
        margin: 0;
        position: relative;

        span {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 120px;
          height: 32px;
          line-height: 32px;
          border-radius: 6px;
          background: linear-gradient(90deg, #749cf9, #345ed3);
          font-size: 16px;
          letter-spacing: 2px;
          color: #fff;
          font-weight: 500;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  .bg_left {
    width: 176px;
    height: 699px;
    position: absolute;
    top: 141px;
    left: -210px;
    background: url("../../images/activity/bgLeft.png") no-repeat top center;
    background-size: cover;
  }

  .bg_right {
    width: 78px;
    height: 365px;
    position: absolute;
    top: 314px;
    right: -110px;
    background: url("../../images/activity/bgRightt.png") no-repeat top center;
    background-size: cover;
  }

  .topLine {
    background: linear-gradient(-90deg, #345ED3, #7A9CF9);
    height: 98px;
  }

  .banner {
    margin: auto;
    .setW-H(1920px, 472px);

    .banner_con{
      position: relative;
      .setW-H(1920px, 472px);
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .content {
    background: rgb(254, 254, 255);
    position: relative;
    //z-index: 99;
    .setW-H(1300px, auto);
    margin: 10px auto 5px auto;
    padding: 50px 0 50px 50px;
    box-shadow: #c1c0c0 0px 0px 20px;

    .box {
      cursor: pointer;
      position: relative;
      .setW-H(375px, 315px);
      float: left;
      border-radius: 20px;
      box-shadow: #ececec 0px 6px 8px;
      overflow: hidden;
      margin: 0 40px 25px 0;

      .title {
        position: absolute;
        left: 20px;
        top: 0px;
        .setW-H(98px, 28px);
        line-height: 28px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }

      .title_bg_blue {
        background: linear-gradient(-90deg, #7A9CF9, #a3b5e7);
      }

      .title_bg_green {
        background: linear-gradient(-90deg, #61C509, #9AEF2C);
      }

      .title_bg_orang {
        background: linear-gradient(-90deg, #F58A3B, #EFCD2C);
      }

      .title_bg_red {

        background: linear-gradient(-90deg, #F5483B, #FE9285);
      }

      img {
        display: block;
        .setW-H(375px, 231px);
      }

      .subtitle {
        font-weight: bold;
        position: absolute;
        left: 0px;
        top: 257px;
        overflow: hidden;
        font-size: 14px;
        color: rgba(92, 92, 91, 1);
        text-align: left;
        text-indent: 20px;
      }

      .datetime {
        position: absolute;
        left: 0px;
        top: 283px;
        font-size: 12px;
        text-indent: 20px;
        color: rgba(92, 92, 91, 1);
      }

      .detailBut {
        font-size: 12px;
        border-radius: 5px;
        text-align: center;
        color: #fff;
        cursor: pointer;
        position: absolute;
        right: 30px;
        bottom: 10px;
        .setW-H(87px, 24px);
        line-height: 24px;
        background: linear-gradient(-90deg, #7A9CF9, #a3b5e7);

        a {
          color: #fff;
        }
      }
    }
  }

}
