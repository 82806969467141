.contain {
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  height: auto;

  .content {
    position: relative;
    z-index: 99;
    width: 1200px;
    margin: 0 auto;
  }

  .slider {
    cursor: pointer;
    //position: absolute;
    position: fixed;
    top: -280px;
    z-index: 999;
    height: 220px;
    width: 100%;
    background: rgba(244, 246, 255, 0.9);
    //background: linear-gradient(0deg, rgba(255, 248, 228, 0.7), #708DD3);
    //background: rgba(114,149,245, 0.7) url("../../images/public/blur-bg.png") no-repeat 1px -40px;
    //background-size: 100% 385px;
    //opacity: 0.98;
  }

  .slider_Show {
    top: 98px;

    animation-name: sliderShow_ani;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }

  .slider_Hide {
    top: -280px;

    animation-name: sliderHide_ani;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }
}

@keyframes sliderShow_ani {
  from {
    top: -280px;
  }
  to {
    top: 98px;
  }
}

@keyframes sliderHide_ani {
  from {
    top: 98px;
  }
  to {
    top: -280px;
  }
}
