.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

.isShow {
  display: block;
}

.isHide {
  display: none;
}

.mask {
  position: fixed;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.wallet_address {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  width: 100%;
  background: white;
  margin: auto;

  .change_lump {
    width: 638px;
    height: auto;
    margin: 20px auto 0;

    .stepLine {
      font-size: 15px;
      color: #333333;
      font-weight: bold;
      position: relative;
      margin: 0;

      span.change {
        position: relative;
        top:-2px;
        width: 120px;
        height: auto;
        margin-right: 10px;
        border: 1px solid #999;
        border-radius: 4px;
        padding: 2px 5px;
        font-size: 12px;
        font-weight: 400;
        color: #999;
        cursor: pointer;
      }
    }

    > span {
      margin-top:20px;
      display: inline-block;
      width: auto;
      height: 38px;
      line-height: 36px;
      background: white;
      border: 2px solid #EDEDEF;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 400;
      text-align: center;
      color: #999999;
      margin-right: 15px;
      padding:0 15px;
      cursor: pointer;
    }

    > span.active {
      color: #333333;
      border: 2px solid #94ABEA;
    }
  }

  .qrcode_lump {
    width: 638px;
    height: auto;
    margin: 0 auto;
    margin-top:28px;
    padding-bottom: 20px;

    .stepLine {
      font-size: 15px;
      color: #333333;
      font-weight: bold;
      position: relative;
      margin: 0;
    }

    .des {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 50px;
      text-align: left;

      span {
        font-size: 16px;
        font-weight: bold;
        color: #CC3333;
        margin:0 5px;
      }

      i {
        font-style: normal;
        font-size: 12px;
        font-weight: 500;
        color: #999999;
        margin-right:5px;
      }
    }

    .qrcode_img {
      background: url("../../images/usercenter/icon_qrcode_bg.png") no-repeat;
      background-size: contain;
      width: 188px;
      height: 188px;
      margin-top: 5px;

      > span {
        display: block;
        width: 167px;
        height: 167px;
        margin: 0 auto;

        > canvas {
          display: block;
          position: relative;
          top: 12px;
          width: 167px !important;
          height: 167px !important;
          margin: 0 auto;
        }
      }
    }

    .save_qrcode_btn {
      display: block;
      text-align: center;
      width: 188px;
      height: 30px;
      line-height: 30px;
      background: #94ABEA;
      border-radius: 5px;
      font-size: 15px;
      font-weight: 500;
      color: white;
      margin-top: 20px;
      cursor: pointer;
    }

    .copy_address {
      text-align: left;
      width: 638px;
      height: auto;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin-top:20px;
      border-bottom:1px solid #E1E1E1;
      padding-bottom:10px;

      .stepLine {
        font-size: 15px;
        color: #333333;
        font-weight: bold;
        position: relative;
        margin: 0;
      }

      > span {
        width: 638px;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        margin: 0 auto;
      }

      > div {
        width: 638px;
        height: auto;
        margin: 0 auto;
        margin-top: 10px;
        cursor: pointer;

        > img {
          width: 15px;
          height: auto;
        }
      }
    }

    .tip_lump {
      text-align: left;
      width: 638px;
      height: auto;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      margin: 0 auto;
      margin-top: 15px;

      > span {
        display: block;
        color: #666666;

        &:last-child {
          color: #333333
        }

        > i {
          color: red;
          font-style: normal;
        }
      }
    }
  }
}

.wallet_protocol {

  .bg {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    margin: auto;
    z-index: 9999;
  }

  .con {
    width: 460px;
    height: 316px;
    background: white;
    border-radius: 10px;
    position: fixed;
    z-index: 9999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    .title {
      text-align: center;
      position: relative;
      width: 100%;
      height: 46px;
      font-size: 15px;
      color: white;
      line-height: 46px;
      background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
      border-radius: 10px 10px 0px 0px;
      margin-bottom: 17px;
      margin-top: -1px;

      .close {
        position: absolute;
        top: 0px;
        right: 10px;
        width: 17px;
        height: 17px;
        cursor: pointer;

        img {
          width: 17px;
          height: 17px;
        }
      }
    }

    .content {
      width: 380px;
      height: auto;
      margin: 0 auto;

      .des {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #CC3333;
        line-height: 20px;
        margin-bottom: 20px;
      }

      span {
        display: block;
        width: 380px;
        height: 40px;
        line-height: 40px;
        background: #F7FBFE;
        border-radius: 5px;
        text-align: left;
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        margin-top: 16px;
        text-indent: 17px;
        cursor: pointer;

        &.active {
          color: white;
          background: #769AF7;
        }
      }

    }
  }
}

.deposit {
  .payList {
    gap: 20px;
    display: flex;
    width: 100%;
    list-style: none;
    // justify-content: space-between;
    margin: 0 0 0 55px;

    li {
      cursor: pointer;
      position: relative;
      border-radius: 13px;
      border: 2px solid rgba(237, 237, 238, 1);
      .setW-H(80px, 95px);

      img {
        .setW-H(25px, 25px);
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        margin: 15px auto 0 auto;
      }

      .payname {
        font-size: 12px;
        color: rgba(51, 51, 51, 1);
        line-height: 17px;
        text-align: center;
        position: absolute;
        left: 0;
        top: 50px;
        width: 100%;
      }

      .paynameMid {
        //line-height: 300%;
      }
    }

    .activeLi {
      border: 2px solid rgba(143, 167, 233, 1);
      background: url("../../images/usercenter/iconPayCheck.png") no-repeat bottom right;
      background-size: 16px 16px;
    }
  }

  .payTip {
    position: relative;
    color: rgba(75, 75, 74, 1);
    font-size: 12px;
    margin-top: 18px;
    text-indent: 220px;

    img {
      left: 200px;
      top: 1px;
      position: absolute;
      .setW-H(16px, 16px);
    }
  }

  .stepLine {
    font-size: 15px;
    color: #333333;
    font-weight: bold;
    position: relative;
    margin: 35px 0 15px 55px;

    .numBg {
      .setW-H(18px, 18px);
      line-height: 18px;
      text-align: center;
      background: url("../../images/usercenter/setpBg.png") no-repeat;
      background-size: cover;
      font-size: 14px;
      color: #fff;
      position: absolute;
      left: -25px;
      top: 2px;
    }
  }

  .usdtDiv {

    .etoken_fast {
      margin: 0 auto;
      width: 638px;
      height: auto;
      background: white;

      .active {
        border: 2px solid #94ABEA;

        .btn {
          display: inline-block;
          color: #333333;
          text-align: center;
          width: 80px;
          height: 30px;
          background: white;
          line-height: 28px;
          margin: 0 auto;
          margin-top: 5px;
          border: 2px solid #EDEDEF;
          border-radius: 6px;
          font-size: 13px;
        }
      }

      > span {
        cursor: pointer;
        width: 638px;
        height: 43px;
        margin: 0 auto;
        margin-bottom: 20px;
        background: rgba(255, 255, 255, 0);
        border: 2px solid #EDEDEF;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 200px 180px 165px auto;

        .icon_lump {
          width: 100%;
          display: grid;
          grid-template-columns: 22px auto;
          height: auto;
          margin-top: 5px;
          margin-left: 15px;

          img {
            position: relative;
            top: 0px;
            width: 22px;
            height: auto;
          }

          > span:last-child {
            font-family: ArialMT;
            font-size: 16px;
            font-weight: bold;
            color: #131313;
            margin-left: 10px;
            line-height: 27px;

            > i {
              font-style: normal;
              font-size: 13px;
              font-weight: 500;
              color: #999999;
              margin-left: 7px;
            }
          }
        }

        .des_01 {
          width: 100%;
          text-align: left;
          height: auto;
          margin: 0 auto;
          margin-top: 5px;

          > span {
            font-size: 12px;
            font-weight: 500;
            color: #999999;
            line-height: 27px;

            > i {
              font-style: normal;
              color: #CC3333;
            }
          }
        }

        .des_02 {
          width: 100%;
          text-align: left;
          height: auto;
          margin: 0 auto;
          margin-top: 5px;

          > span {
            font-size: 13px;
            font-weight: bold;
            color: #333333;
            line-height: 27px;
            margin-top: 22px;

            > i {
              font-style: normal;
              font-size: 15px;
              color: #CC3333;
            }
          }
        }

        .btn {
          display: inline-block;
          color: white;
          text-align: center;
          width: 80px;
          height: 30px;
          background: #99AFEB;
          line-height: 30px;
          margin: 0 auto;
          margin-top: 5px;
          border-radius: 6px;
          font-size: 13px;
        }
      }
    }

    .etoken_pic {
      padding-top: 30px;
      position: relative;
      margin: 0 auto;
      width: 638px;
      height: auto;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 638px;
        height: auto;
      }
    }

    .usdtTypeBox {
      margin: 19px 0 20px 55px;

      .usdtTypeItem {
        cursor: pointer;
        float: left;
        width: 320px;
        height: 38px;
        line-height: 38px;
        background: #FFFFFF;
        border: 2px solid #EDEDEF;
        border-radius: 10px;
        background: url("../../images/public/tether-usdt.png") no-repeat 14px 8px;
        background-size: 22px 22px;
        margin-right: 15px;
        margin-bottom: 15px;
        position: relative;

        .usdtTitle {
          padding-left: 44px;
          font-size: 15px;
          font-weight: 400;
          color: #333333;
          width: 150px;
          display: inline-block;
        }

        .usdtRate {
          position: relative;
          right: -40px;
          text-align: right;
          font-size: 13px;
          color: #666666;

          font {
            color: rgb(204, 51, 51);
          }
        }
      }

      .usdtTypeItemAct {
        border: 2px solid #94ABEA;
      }
    }

    .usdtPayUl {
      padding-left: 55px;

      li {
        cursor: pointer;
        margin-right: 10px;
        float: left;
        width: 98px;
        height: 38px;
        background: #FFFFFF;
        border: 2px solid #EDEDEF;
        border-radius: 10px;
        line-height: 38px;
        text-align: center;
        font-weight: 400;
        color: #999999;
      }

      .actLi {
        border: 2px solid #94ABEA;
        font-size: 15px;
        font-weight: 400;
        color: #333333;
      }
    }

    .usdtPayBox {
      z-index: 1002;
      margin: auto;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      .setW-H(460px, 576px);
      overflow: hidden;
      border-radius: 10px;
      background: #fff;

      .toptitle {
        position: relative;
        line-height: 46px;
        text-align: center;
        font-size: 16px;
        font-family: Arial;
        font-weight: bold;
        color: #FFFEFE;
        background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
        height: 46px;

        .close {
          cursor: pointer;
          background: url("../../images/usercenter/usdtCloseBut.png") no-repeat top center;
          background-size: 15px 15px;
          position: absolute;
          .setW-H(15px, 15px);
          top: 15px;
          right: 17px;
        }
      }

      .subtitle {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        margin: 19px 0 0 0;
      }

      .moneyUsdt {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        color: #CC3333;
        //margin-top: 8px;
      }

      .moneyRMB {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        //margin-top: 5px;
      }

      .copyBox {
        position: relative;
        width: 380px;
        height: 42px;
        background: #FFFFFF;
        border: 2px solid #EDEDEE;
        border-radius: 5px;
        margin: 15px auto 0 auto;
        line-height: 42px;
        padding: 0 54px 0 26px;
        overflow: hidden;
        white-space: nowrap;

        .content {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-align: center;
        }

        .copyBut {
          position: absolute;
          .setW-H(16px, 16px);
          background: url("../../images/usercenter/usdtCopy.png") no-repeat top center;
          background-size: 16px 16px;
          top: 12px;
          right: 26px;
          cursor: pointer;
        }
      }

      .qrCode {
        margin: 27px auto 19px auto;
        .setW-H(204px, 204px);
        padding-top: 10px;
        background: url("../../images/usercenter/usdtQrCodeBg.png") no-repeat center center;
        background-size: 204px 204px;

        img {
          .setW-H(185px, 185px);
          display: block;
          margin: auto;
        }
      }

      .loading {
        margin: 27px auto 19px auto;
        .setW-H(204px, 204px);
        padding-top: 10px;
        background: url("../../images/usercenter/usdtLoding.png") no-repeat center center;
        background-size: 44px 56px;
      }

      .tip {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        margin-bottom: 29px;
      }

      .butPay {
        width: 204px;
        height: 40px;
        background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
        border-radius: 5px;
        margin: auto;
        font-size: 15px;
        font-weight: bold;
        color: #FEFEFE;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
      }
    }
  }

  .conLine {
    font-size: 15px;
    margin-top: 5px;
    position: relative;
    height: 30px;
    line-height: 30px;
    text-indent: 55px;
    color: #666666;

    .but {
      position: absolute;
      left: 230px;
      top: 0px;
      border-radius: 5px;
      width: 93px;
      height: 30px;
      text-indent: 0px;
      text-align: center;
      cursor: pointer;
      color: #fff;
      background: linear-gradient(-90deg, rgb(127, 159, 235), rgb(176, 196, 250));
    }
  }

  .formBox {
    //height: 420px;

    .chosseMoney{
      padding-left: 70px;
      margin-bottom: 25px;
      .label{
        font-size: 14px;
        line-height: 30px;
        margin-right: 5px;
        color: #4B4B4A;
        margin-bottom: 18px;
      }
      .fastList{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        span{
          height: 32px;
          line-height: 32px;
          text-align: center;
          width: 65px;
          background: #FFFFFF;
          border: 1px solid #EDEDEE;
          border-radius: 5px;
          font-size: 13px;
          cursor: pointer;
          &.active{
            color: #FFFFFF;
            background: #99AFEB;
          }
        }
      }
    }
    .txtLine {
      position: relative;
      color: #4b4b4a;
      text-indent: 138px;
      font-size: 12px;
      padding-bottom: 16px;
    }

    .butSubmit {
      margin: 0 0 0 331px;
      bottom: 0;
      font-size: 14px;
      border: none;
      border-radius: 5px;
      width: 108px;
      height: 30px;
      text-align: center;
      cursor: pointer;
      color: #fff;
      background: linear-gradient(-90deg, #7f9feb, #b0c4fa);
    }

    .inputLine {
      position: relative;
      color: #4b4b4a;
      margin-bottom: 25px;
      height: auto;
      padding-left: 30px;

      .usdt_address_wrong {
        width: 120px;
        color: red;
        position: absolute;
        left: 140px;
        top: 33px;
        line-height: 20px;
        height: 20px;
      }

      .address_pop {
        background: white;
        position: absolute;
        width: 274px;
        height: auto;
        max-height: 212px;
        left: 131px;
        bottom: 31px;
        margin: auto;
        box-shadow: 0 1px 5px 0 #EDEDEE;
        overflow: hidden;
        border-radius: 6px;
        border: 1px solid #EDEDEE;

        .title_bar {
          width: 274px;
          height: 35px;
          line-height: 35px;
          border-bottom: 1px solid #EDEDEE;
          border-radius: 6px 6px 0 0;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }

        .ele {
          width: 274px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          color: #666666;
          border-bottom: 1px solid #EDEDEE;
          cursor: pointer;
        }
      }

      .title {
        font-size: 14px;
        float: left;
        .setW-H(111px, 32px);
        line-height: 30px;
        text-align: right;
        margin-right: 5px;
        color: #4B4B4A;
      }

      .inputBox {
        float: left;

        input {
          padding-top: 1px;
          font-size: 12px;
          text-indent: 10px;
          float: left;
          .setW-H(274px, 32px);
          line-height: 32px;
          padding: 0;
          background: transparent;
          border: 1px solid rgba(237, 237, 237, 1);
          border-radius: 5px;
          box-shadow: none;
          color: #000;
          text-align: left;
          //letter-spacing: -1px !important;
          //text-indent: 10px;
        }
      }

      .inputMsg {
        color: #4B4B4A !important;
        height: 32px;
        line-height: 32px;
        width: 300px;
        float: left;
        margin-left: 16px;
        font-size: 12px;
      }

      .inputErr {
        color: red;
      }
    }

    .butCenter {
      margin: auto;
    }

    .butbox {
      position: absolute;
      bottom: 20px;

      .butSubmit {
        margin: 0 0 0 331px;
        bottom: 0;
        font-size: 14px;
        border: none;
        border-radius: 5px;
        width: 108px;
        height: 30px;
        text-align: center;
        cursor: pointer;
        color: #fff;
        background: linear-gradient(-90deg, rgb(127, 159, 235), rgb(176, 196, 250));
      }
    }
  }

  .tipLine {
    margin: 30px 0 24px 0;
    position: relative;
    color: rgba(250, 68, 136, 1);
    font-size: 12px;
    text-align: center;

    .icon {
      position: absolute;
      left: 105px;
      right: 0;
      background: url("../../images/usercenter/iconTip.png") no-repeat;
      .setW-H(16px, 18px);
      background-size: cover;
    }
  }
}

.fastPay{
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .fastPopupWrap{
    background: #fff;
  }
  .title {
    height: 46px;
    line-height: 46px;
    text-align: center;
    font-size: 16px;
    color: #FFFEFE;
    background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
    position: relative;

    img {
      display: block;
      position: absolute;
      top: 15px;
      right: 17px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
  .wrap{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 19px 28px 24px;
    .topWrap{
      .line1{
        font-size: 14px;
        color: #666666;
        span{
          color: #CC3333;
        }
      }
      .line2{
        font-size: 20px;
        color: #333333;
        span{
          font-weight: bold;
          font-size: 30px;
        }
      }
    }
    .center{
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      .qrcode{
        width: 204px;
        height: 204px;
        border: 1px solid #5B9CE4;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .platForm{
        width: auto;
        height: 87px;
      }
      .timeOut{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255,.9);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 14px;
      }
    }
    .tips{
      color: #999999;
      margin: 17px 0 40px;
    }
    .btn{
      width: 204px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
      border-radius: 5px;
      font-size: 15px;
      font-weight: bold;
      color: #FEFEFE;
      cursor: pointer;
    }
  }
  .footer{
    margin-top: 33px;
    color: #999999;
    line-height: 24px;
    font-size: 14px;
    padding: 0 54px 24px 28px;
    .color_1{
      color: #2a6bef;
      text-decoration: dashed;
    }
    .color_2{
      color: #ff7217;
    }
  }
}