.user_center_deposit,
.user_center_baseinfo {

  .ant-select {
    width: 274px !important;
  }

  .ant-select-selector {
    position: relative;
    text-indent: 0px !important;
    height: 29px !important;
    background: transparent !important;
    border: 1px solid #ededed !important;
    border-radius: 5px !important;
    box-shadow: none;
    color: #000;
    text-align: left;

    .ant-select-selection-item {
      position: unset !important;
      top: -1px !important;
    }

    .ant-select-selection-placeholder {
      color: #B0B0B0 !important;
      position: relative !important;
      top: -1px !important;
      font-size: 12px !important;
    }

    .ant-select-selection-search {

    }
  }

  .baseInfoShowPwd{
    .ant-input-suffix {
      top: -7px !important;
    }
  }
}

.user_center_cashlog {
  .ant-table-thead, .ant-table-tbody {
    .ant-table-cell:nth-child(1){
      padding-left: 33px;
    }
  }
}
