.c {
  clear: both;
}

.homeservice {
  font: "微软雅黑", "黑体";
  margin-top: 80px;

  .serviceTitle {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #695C94;
  }

  .serviceTitleSubTitle {
    padding-top: 37px;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #768CBE;
    line-height: 30px;

  }

  .serviceLine {
    position: relative;

    .circleGrid {
      width: 1100px;
      height: 350px;
      margin: 0 auto;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: auto auto auto auto;

      .cell {
        position: relative;

        .circleProgress_wrapper1 {
          width: 171px;
          height: 174px;
          position: absolute;
          margin: 48px auto 0 auto;
          left: 0;
          right: 0;
          //background: url("../../../images/public/serviceCircleBg1.png") no-repeat;
          //background-size: cover;

          .circleTxt {
            font-size: 40px;
            font-weight: bold;
            color: #8fa4e1;
            position: absolute;
            left: 66px;
            top: 62px;

            span {
              font-size: 14px;
              font-weight: normal;
            }
          }

          .circleTitlt {
            position: absolute;
            top: 193px;
            left: 0px;
            right: 0px;
            margin: auto;
            width: 160px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #4C5665;
          }

          .wrapper {
            width: 100px;
            height: 200px;
            position: absolute;
            top: 0;
            right: 0;
            overflow: hidden;
          }

          .right {
            right: 0;
          }

          .left {
            left: 0;
          }

          .circleProgress {
            position: relative;
            width: 145px;
            height: 145px;
            border: 15px solid transparent;
            border-radius: 50%;
            position: absolute;
            right: 23px;
            top: 21px;
          }

          .yuan {
            border-radius: 50%;
            position: absolute;
            width: 12px;
            height: 12px;
            background: #8fa4e1;
            left: 99px;
            top: 6px;
          }

          .rightcircle_1 {
            border-top: 12px solid #8fa4e1;
            border-left: 12px solid #8fa4e1;
            transform: rotate(-45deg);

            animation-name: right_50;
            animation-duration: 2s;
            animation-fill-mode: forwards;
          }

          .rightcircle_2 {
            border-top: 12px solid #8fa4e1;
            border-left: 12px solid #8fa4e1;
            transform: rotate(-45deg);

            animation-name: right_30;
            animation-duration: 2s;
            animation-fill-mode: forwards;
          }

          .rightcircle_3 {
            border-top: 12px solid #8fa4e1;
            border-left: 12px solid #8fa4e1;
            transform: rotate(-45deg);

            animation-name: right_100;
            animation-duration: 2s;
            animation-fill-mode: forwards;
          }

          .leftcircle {
            border-bottom: 20px solid green;
            border-left: 20px solid green;
            left: 0;
            transform: rotate(45deg);
          }
        }

        .circleProgress_wrapper2 {
          width: 171px;
          height: 174px;
          position: absolute;
          margin: 48px auto 0 auto;
          left: 0;
          right: 0;
          //background: url("../../../images/public/serviceCircleBg2.png") no-repeat;
          //background-size: cover;

          .circleTxt {
            font-size: 40px;
            font-weight: bold;
            color: #8fa4e1;
            position: absolute;
            left: 66px;
            top: 62px;

            span {
              font-size: 14px;
              font-weight: normal;
            }
          }

          .circleTitlt {
            position: absolute;
            top: 193px;
            left: 0px;
            right: 0px;
            margin: auto;
            width: 160px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #4C5665;
          }

          .wrapper {
            width: 100px;
            height: 200px;
            position: absolute;
            top: 0;
            right: 0;
            overflow: hidden;
          }

          .right {
            right: 0;
          }

          .left {
            left: 0;
          }

          .circleProgress {
            position: relative;
            width: 145px;
            height: 145px;
            border: 15px solid transparent;
            border-radius: 50%;
            position: absolute;
            right: 23px;
            top: 21px;
          }

          .yuan {
            border-radius: 50%;
            position: absolute;
            width: 12px;
            height: 12px;
            background: #8fa4e1;
            left: 99px;
            top: 6px;
          }

          .rightcircle_1 {
            border-top: 12px solid #8fa4e1;
            border-left: 12px solid #8fa4e1;
            transform: rotate(-45deg);

            animation-name: right_50;
            animation-duration: 2s;
            animation-fill-mode: forwards;
          }

          .rightcircle_2 {
            border-top: 12px solid #8fa4e1;
            border-left: 12px solid #8fa4e1;
            transform: rotate(-45deg);

            animation-name: right_30;
            animation-duration: 2s;
            animation-fill-mode: forwards;
          }

          .rightcircle_3 {
            border-top: 12px solid #8fa4e1;
            border-left: 12px solid #8fa4e1;
            transform: rotate(-45deg);

            animation-name: right_100;
            animation-duration: 2s;
            animation-fill-mode: forwards;
          }

          .leftcircle {
            border-bottom: 20px solid green;
            border-left: 20px solid green;
            left: 0;
            transform: rotate(45deg);
          }
        }

        .circleProgress_wrapper3 {
          width: 171px;
          height: 174px;
          position: absolute;
          margin: 48px auto 0 auto;
          left: 0;
          right: 0;
          //background: url("../../../images/public/serviceCircleBg3.png") no-repeat;
          //background-size: cover;

          .circleTxt {
            font-size: 40px;
            font-weight: bold;
            color: #8fa4e1;
            position: absolute;
            left: 66px;
            top: 62px;

            span {
              font-size: 14px;
              font-weight: normal;
            }
          }

          .circleTitlt {
            position: absolute;
            top: 193px;
            left: 0px;
            right: 0px;
            margin: auto;
            width: 160px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #4C5665;
          }

          .wrapper {
            width: 100px;
            height: 200px;
            position: absolute;
            top: 0;
            right: 0;
            overflow: hidden;
          }

          .right {
            right: 0;
          }

          .left {
            left: 0;
          }

          .circleProgress {
            position: relative;
            width: 145px;
            height: 145px;
            border: 15px solid transparent;
            border-radius: 50%;
            position: absolute;
            right: 23px;
            top: 21px;
          }

          .yuan {
            border-radius: 50%;
            position: absolute;
            width: 12px;
            height: 12px;
            background: #8fa4e1;
            left: 99px;
            top: 6px;
          }

          .rightcircle_1 {
            border-top: 12px solid #8fa4e1;
            border-left: 12px solid #8fa4e1;
            transform: rotate(-45deg);

            animation-name: right_50;
            animation-duration: 2s;
            animation-fill-mode: forwards;
          }

          .rightcircle_2 {
            border-top: 12px solid #8fa4e1;
            border-left: 12px solid #8fa4e1;
            transform: rotate(-45deg);

            animation-name: right_30;
            animation-duration: 2s;
            animation-fill-mode: forwards;
          }

          .rightcircle_3 {
            border-top: 12px solid #8fa4e1;
            border-left: 12px solid #8fa4e1;
            transform: rotate(-45deg);

            animation-name: right_100;
            animation-duration: 2s;
            animation-fill-mode: forwards;
          }

          .leftcircle {
            border-bottom: 20px solid green;
            border-left: 20px solid green;
            left: 0;
            transform: rotate(45deg);
          }
        }

        .circleProgress_wrapper4 {
          width: 171px;
          height: 174px;
          position: absolute;
          margin: 48px auto 0 auto;
          left: 0;
          right: 0;
          //background: url("../../../images/public/serviceCircleBg4.png") no-repeat;
          //background-size: cover;

          .circleTxt {
            font-size: 40px;
            font-weight: bold;
            color: #8fa4e1;
            position: absolute;
            left: 66px;
            top: 62px;

            span {
              font-size: 14px;
              font-weight: normal;
            }
          }

          .circleTitlt {
            position: absolute;
            top: 193px;
            left: 0px;
            right: 0px;
            margin: auto;
            width: 160px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #4C5665;
          }

          .wrapper {
            width: 100px;
            height: 200px;
            position: absolute;
            top: 0;
            right: 0;
            overflow: hidden;
          }

          .right {
            right: 0;
          }

          .left {
            left: 0;
          }

          .circleProgress {
            position: relative;
            width: 145px;
            height: 145px;
            border: 15px solid transparent;
            border-radius: 50%;
            position: absolute;
            right: 23px;
            top: 21px;
          }

          .yuan {
            border-radius: 50%;
            position: absolute;
            width: 12px;
            height: 12px;
            background: #8fa4e1;
            left: 99px;
            top: 6px;
          }

          .rightcircle_1 {
            border-top: 12px solid #8fa4e1;
            border-left: 12px solid #8fa4e1;
            transform: rotate(-45deg);

            animation-name: right_50;
            animation-duration: 2s;
            animation-fill-mode: forwards;
          }

          .rightcircle_2 {
            border-top: 12px solid #8fa4e1;
            border-left: 12px solid #8fa4e1;
            transform: rotate(-45deg);

            animation-name: right_30;
            animation-duration: 2s;
            animation-fill-mode: forwards;
          }

          .rightcircle_3 {
            border-top: 12px solid #8fa4e1;
            border-left: 12px solid #8fa4e1;
            transform: rotate(-45deg);

            animation-name: right_100;
            animation-duration: 2s;
            animation-fill-mode: forwards;
          }

          .leftcircle {
            border-bottom: 20px solid green;
            border-left: 20px solid green;
            left: 0;
            transform: rotate(45deg);
          }
        }

        .serviclog {
          position: absolute;

          .log {
            position: absolute;
            top: 69px;
            left: 60px;
            width: 162px;
            height: 41px;
          }

          .logtxt {
            font-size: 14px;
            width: 153px;
            height: 114px;
            position: absolute;
            top: 156px;
            left: 80px;
          }
        }
      }
    }
  }
}


@keyframes right_50 {
  from {
    transform: rotate(-45deg);
  }
  to {

    transform: rotate(50deg);
  }
}

@keyframes right_30 {
  from {
    transform: rotate(-45deg);
  }
  to {

    transform: rotate(30deg);
  }
}

@keyframes right_100 {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(100deg);
  }
}
