.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

ul {
  list-style: none;
}

.contain {
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  height: auto;

  .topLine {
    background: rgb(244, 246, 252);
    height: 98px;
  }

  .content {
    background: url("../../images/public/pageSportBg.png") no-repeat top center;
    background-size: cover;

    .shadowLine {
      width: 100%;
      height: 20px;
      background: linear-gradient(0deg, rgba(212, 217, 245, 0), rgba(177, 183, 218, 0.15));
      top: 99px;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
    }

    .con {
      position: relative;
      width: 1185px;
      height: 840px;
      margin: auto;
    }

    .banner {
      position: absolute;
      top: 103px;
      left: -222px;

      .banner_con{
        .setW-H(844px, 736px);
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .banner1 {
      float: left;
      padding-top: 103px;

      .banner_con{
        .setW-H(844px, 747px);
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .banner2 {
      float: left;
      padding-top: 103px;

      .banner_con{
        .setW-H(844px, 752px);
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .banner3 {
      float: left;
      padding-top: 103px;

      .banner_con{
        .setW-H(844px, 757px);
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .platform {
      top: 30px;
      right: 0;
      position: absolute;
      float: right;
      padding: 0;

      li {
        cursor: pointer;
        float: left;
        .setW-H(128px, 50px);
        background: url("../../images/public/platformBg.png");
        background-size: cover;
        text-align: center;
        line-height: 44px;
        margin-left: 3px;
        font-size: 15px;
        font-weight: bold;
        color: #757C94;
      }

      .actli {
        background: url("../../images/public/platformBgAct.png");
        background-size: cover;
        color: #FFFFFF;
      }
    }

    .fontTitle {
      position: absolute;
      top: 233px;
      right: 319px;

      .fontTItle_con{
        top: 233px;
        right: 319px;
        .setW-H(294px, 76px);

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .fontTitle1 {
      position: absolute;
      top: 233px;
      right: 284px;

      .fontTItle_con{
         .setW-H(335px, 76px);

         img {
           width: 100%;
           height: auto;
         }
       }
    }
    .fontTitleIM {
      position: absolute;
      top: 233px;
      right: 390px;

      .fontTItle_con{
        .setW-H(226px, 76px);

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .fontTitle2 {
      position: absolute;
      top: 233px;
      right: 381px;

      .fontTItle_con{
        .setW-H(226px, 76px);

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .fontTitle3 {
      position: absolute;
      top: 233px;
      right: 339px;

      .fontTItle_con{
        .setW-H(276px, 76px);

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .fontContent {
      position: absolute;
      top: 331px;
      right: 21px;
      width: 588px;
      font-size: 14px;
      color: rgba(118, 140, 190, 1);
      line-height: 30px;
    }

    .butTZ {
      cursor: pointer;
      background: url("../../images/public/butTZ.png");
      background-size: cover;
      position: absolute;
      top: 461px;
      right: 396px;
      .setW-H(210px, 54px);

      img {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 18px;
        left: 15px;
      }
    }

    .butTZ:hover {
      background: url("../../images/public/butTZAct.png");
      background-size: cover;
    }
  }
}
