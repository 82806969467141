@import "../util/cssImgRemoteSrc";

.container {
  //width: 100%;
  //height: 100%;
  //overflow-y: auto;
  position: fixed;
  width: 100%;
  height: 100% !important;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  //padding-bottom: .9rem;
  overflow: hidden;
  z-index: 3;

  .loading {
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: auto;

    .icon {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url('../../images/timg2.gif') no-repeat;
      background-size: cover;
      width: 100px;
      height: 100px;
      margin: auto;
    }

    .failed {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 100px;
      font-size: 30px;
      font-weight: bold;
      color: black;
      margin: auto;
      text-align: center;
    }
  }

  .iframe {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
