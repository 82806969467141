.footer_bar {
  position: relative;
  z-index: 999;
  background: rgba(84, 93, 110, 1);
  min-width: 1200px;
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 20px;

  .info {
    width: 100%;

    .con_01 {
      width: 1200px;
      height: 82px;
      margin: 0 auto;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: auto auto auto auto auto auto auto;


      .brand {
        position: relative;
        opacity: 0.4;

        img {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          height: 46px;
        }
      }
    }

    .con_02 {
      color: rgba(255, 255, 255, .4);
      padding-top: 25px;
      width: 1200px;
      height: 83px;
      margin: 0 auto;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }

    .con_03 {
      width: 420px;
      margin: 0 auto;
      margin-top: 11px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: auto auto;


      .img_con {
        opacity: .4;
        text-align: center;
        display: block;
        position: relative;
        margin-bottom: 8px;

        img {
          display: block;
          width: 41px;
          margin: auto;
        }

        span {
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
        }
      }

    }
  }

  .links {
    width: 100%;
    height: auto;

    .con {
      width: 660px;
      height: auto;
      margin: 0 auto;
      overflow: hidden;

      .ele {
        display: inline-block;
        color: white;
        font-size: 14px;
        margin-top: 25px;

        a {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.4);
        }


        span {
          color: #798799;
          margin: 0 30px;
        }

        &:last-child {
          span {
            display: none;
          }
        }

      }
    }

    .rights {
      color: rgba(255, 255, 255, .4);
      margin-top: 25px;
      width: 100%;
      line-height: 24px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
}
