.c {
  clear: both;
}

.match {
  font: "微软雅黑", "黑体";
  margin-top: 80px;

  .matchTitle {
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    color: #fff;
  }

  .matchTitleSubTitle {
    font-weight: bold;
    position: relative;
    text-align: center;
    padding-top: 20px;
    font-family: Arial;
    margin: auto;
    width: 320px;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;

    .iconleft {
      top: 23px;
      position: absolute;
      width: 14px;
      height: 14px;
      left: 0;
      background: #CDFCFF;
      transform: rotate(45deg);
    }

    .iconRight {
      top: 23px;
      right: 0;
      position: absolute;
      width: 14px;
      height: 14px;
      background: #CDFCFF;
      transform: rotate(45deg);
    }
  }

  .matchLine {
    padding-top: 36px;
    position: relative;

    .matchBox:hover{
      /*.styles__cards__2ScT2:hover {
        -webkit-transform: scale(1.04);
        transform: scale(1.04);
      }*/
      transform: scale(1.02);
    }

    .matchBox {
      z-index: 10;
      float: left;
      position: relative;
      margin: auto;
      width: 427px;
      height: 533px;
      background: url("../../../images/public/matchBg.png") no-repeat;
      background-size: cover;
      transition: 0.3s;


      .matchBoxTitle {
        font-weight: bold;
        text-align: center;
        color: #333333;
        font-size: 28px;
        padding-top: 30px;
      }

      .matchBoxDateTime {
        text-align: center;
        color: #4B4B4B;
        font-size: 14px;
        padding-top: 16px;
      }

      .matchBoxLeftTeamLogo {
        position: absolute;
        width: 149px;
        height: 142px;
        background: url("../../../images/public/matchTeamBg.png") no-repeat;
        background-size: cover;
        left: 16px;
        top: 129px;
      }

      .matchBoxRightTeamLogo {
        position: absolute;
        width: 149px;
        height: 142px;
        background: url("../../../images/public/matchTeamBg.png") no-repeat;
        background-size: cover;
        right: 16px;
        top: 129px;
      }

      .matchTeamNameleft {
        text-align: center;
        overflow: hidden;
        font-size: 18px;
        color: #333333;
        width: 150px;
        height: 22px;
        line-height: 22px;
        position: absolute;
        left: 16px;
        top: 271px;
      }

      .matchTeamNameRight {
        text-align: center;
        overflow: hidden;
        font-size: 18px;
        color: #333333;
        width: 150px;
        height: 22px;
        line-height: 22px;
        position: absolute;
        right: 16px;
        top: 271px;
      }

      .matchBoxRightTeamLogo img,
      .matchBoxLeftTeamLogo img {
        display: block;
        margin: 30px auto 0 auto;
        width: 83px;
        height: 83px;
      }

      .matchBoxVSLogo {
        position: absolute;
        width: 79px;
        height: 79px;
        background: url("../../../images/public/matchTeamBgSmall.png") no-repeat;
        background-size: cover;
        margin: auto;
        right: 0;
        left: 0;
        top: 171px;

        img {
          margin: 32px auto 0 auto;
          display: block;
          width: 39px;
          height: 12px;
        }
      }

      .matchScoreUl {
        width: 334px;
        margin: 221px auto 0 auto;
        padding: 0;
        display: flex;
        list-style: none;
        justify-content: space-between;

        li {
          overflow: hidden;
          width: 104px;
          height: 61px;
          background: url("../../../images/public/matchScoreBg.png") no-repeat;
          background-size: cover;
          font-size: 14px;
          text-align: center;

          span {
            display: block;
            margin-top: 8px;
          }
        }
      }

      .matchBetting {
        position: absolute;
        left: 0;
        margin: auto;
        right: 0;
        bottom: 61px;
        width: 170px;
        height: 63px;
        background: url("../../../images/public/matchBettingBg.png") no-repeat;
        background-size: contain;
        font-size: 20px;
        line-height: 50px;
        cursor: pointer;
        text-align: center;
        color: #fff;
      }
    }

    .matchBox:nth-child(1),
    .matchBox:nth-child(3) {
      transform: scale(.85);
    }

    .matchBox:nth-child(1):hover,
    .matchBox:nth-child(3):hover{
      transform: scale(.88);
    }

    .matchBox:nth-child(2) {
      margin: 0 -20px;
    }

    .matchGrayBg {
      top: 330px;
      position: absolute;
      background: url("../../../images/public/matchGrayBg.png") no-repeat;
      width: 1272px;
      height: 410px;
      background-size: cover;
    }
  }

}

