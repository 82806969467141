.attention_detail {
  width: 100%;
  padding: 20px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  //overflow-y: scroll;

  .qrappdown {
    width: 150px;
    height: 150px;
    margin: 10px auto;
    background: url("../../images/app/appqrcode.png") no-repeat center center;
    background-size: cover;
  }

  .i-main {

    img {
      width: 100%;
      display: block;
      margin: auto;
    }
  }


  .butBack {
    cursor: pointer;
    margin: auto;
    width: 128px;
    height: 50px;
    background: url("../../images/public/platformBgAct.png");
    background-size: cover;
    color: #FFFFFF;
    text-align: center;
    line-height: 44px;
    font-size: 15px;
    font-weight: bold;
  }
}

/*
 *  STYLE 7
 */

#style-7::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

#style-7::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
  display: block;
}

#style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgb(73, 125, 189);
}

