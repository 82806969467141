.mediabox-img {
    font-family: "blur-up: auto", "object-fit: cover";
}
/*.wrapper {*/
/*    width: 80%;*/
/*    min-width: 320px;*/
/*    max-width: 900px;*/
/*}*/

/*.mediabox {*/
/*    position: relative;*/
/*    display: block;*/
/*    height: 0;*/
/*    width: 100%;*/
/*    padding-bottom: 66.6667%;*/
/*}*/

/*.mediabox-img.ls-blur-up-is-loading,*/
/*.mediabox-img.lazyload:not([src]) {*/
/*    visibility: hidden;*/
/*}*/

.ls-blur-up-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;

    /* only if you want to change the blur-up option from always to auto or want to use blur up effect without a lowsrc image. */
    /*font-family: "blur-up: auto", "object-fit: cover";*/

    /*object-fit: cover;*/
}
.game_img_im {
    position: absolute;
    right: 0!important;
    bottom: 0!important;
    width: 2.07rem!important;
    height: auto!important;
    display: block;

    /* only if you want to change the blur-up option from always to auto or want to use blur up effect without a lowsrc image. */
    /*font-family: "blur-up: auto", "object-fit: cover";*/

    /*object-fit: cover;*/
}
.game_img_ysb {
    position: absolute;
    right: 0!important;
    bottom: 0!important;
    width: 1.67rem!important;
    height: auto!important;
    display: block;

    /* only if you want to change the blur-up option from always to auto or want to use blur up effect without a lowsrc image. */
    /*font-family: "blur-up: auto", "object-fit: cover";*/

    /*object-fit: cover;*/
}

.ls-blur-up-img {
    filter: blur(10px);
    opacity: 1;
    transition: opacity 1000ms, filter 1500ms;
}

.ls-blur-up-img.ls-inview.ls-original-loaded {
    opacity: 0;
    filter: blur(5px);
}
