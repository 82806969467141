@import "../util/cssImgRemoteSrc";

.egamePagtion{
  padding-left: 300px;

  .ant-pagination-item-link{
    border: none !important;
  }


  .ant-pagination-options-quick-jumper,
  .ant-pagination-total-text {
    height: 30px !important;
    line-height: 30px !important;
  }

  .ant-select-selector{
    height: 30px !important;
  }

  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next
  {
    overflow: hidden;
    width: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
    min-width: 30px !important;
    line-height: 30px;
    border: 1px solid #E0E5F6 !important;
    border-radius: 5px !important;
  }
  .ant-pagination-item-active{
    a {
      border: none !important;
      display: block;
      width: 30px !important;
      height: 30px !important;
      background: #516FB1;
      color: #FFFFFF !important;
    }
  }
  .ant-pagination-item{
    a{
      padding: 0px !important;
      margin: 0px !important;
    }
  }
}

.number_01 {
  background: url("../../images/egame/number.png") repeat-y;
  .getBgSrc("/egame/number.png", 0 0);
  top: 12px;
  left: 0px;
  width: 34px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  transition: background 1s;
  transition-timing-function: ease;
}

.number_02 {
  background: url("../../images/egame/number.png") repeat-y;
  .getBgSrc("/egame/number.png", 0 0);
  top: 12px;
  left: 40px;
  width: 34px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  transition: background 1s;
  transition-timing-function: ease;
}

.number_03 {
  background: url("../../images/egame/number.png") repeat-y;
  .getBgSrc("/egame/number.png", 0 0);
  top: 12px;
  left: 80px;
  width: 34px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  transition: background 1s;
  transition-timing-function: ease;
}

.number_04 {
  background: url("../../images/egame/number.png") repeat-y;
  .getBgSrc("/egame/number.png", 0 0);
  top: 12px;
  left: 120px;
  width: 34px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  transition: background 1s;
  transition-timing-function: ease;
}

.number_05 {
  background: url("../../images/egame/number.png") repeat-y;
  .getBgSrc("/egame/number.png", 0 0);
  top: 12px;
  left: 160px;
  width: 34px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  transition: background 1s;
  transition-timing-function: ease;
}

.number_06 {
  background: url("../../images/egame/number.png") repeat-y;
  .getBgSrc("/egame/number.png", 0 0);
  top: 12px;
  left: 200px;
  width: 34px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  transition: background 1s;
  transition-timing-function: ease;
}

.number_07 {
  background: url("../../images/egame/number.png") repeat-y;
  .getBgSrc("/egame/number.png", 0 0);
  top: 12px;
  left: 240px;
  width: 34px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  transition: background 1s;
  transition-timing-function: ease;
}

.number_08 {
  background: url("../../images/egame/number.png") repeat-y;
  .getBgSrc("/egame/number.png", 0 0);
  top: 12px;
  left: 280px;
  width: 34px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  transition: background 1s;
  transition-timing-function: ease;
}

.number_09 {
  background: url("../../images/egame/number.png") repeat-y;
  .getBgSrc("/egame/number.png", 0 0);
  top: 12px;
  left: 320px;
  width: 34px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  transition: background 1s;
  transition-timing-function: ease;
}

.number_10 {
  background: url("../../images/egame/number.png") repeat-y;
  .getBgSrc("/egame/number.png", 0 0);
  top: 12px;
  left: 360px;
  width: 34px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  transition: background 1s;
  transition-timing-function: ease;
}

.number_11 {
  background: url("../../images/egame/number.png") repeat-y;
  .getBgSrc("/egame/number.png", 0 0);
  top: 12px;
  left: 407px;
  width: 34px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  transition: background 1s;
  transition-timing-function: ease;
}

.number_12 {
  background: url("../../images/egame/number.png") repeat-y;
  .getBgSrc("/egame/number.png", 0 0);
  top: 12px;
  left: 447px;
  width: 34px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  transition: background 1s;
  transition-timing-function: ease;
}

.egame_collect {
  background: url("../../images/egame/collect.png");
  .getBgSrc("/egame/collect.png");
  background-size: cover;
  width: 20px;
  height: 19px;
  margin-top: 8px;
  box-shadow: none;
}

.egame_collect_active {
  background: url("../../images/egame/collected.png");
  .getBgSrc("/egame/collected.png");
  background-size: cover;
  width: 20px;
  height: 19px;
  margin-top: 8px;
}
