@import "../util/cssImgRemoteSrc";

.contain {
  width: 100%;
  height: auto;
  background: #EBEEFF;

  .bg {
    background: url("../../images/egame/egame_bg.png") no-repeat center 90px;
    .getBgSrc("/zh/egame_bg.png", center 90px);
    width: 100%;
    height: 480px;
    margin: 0 auto;
    position: relative;
    border-bottom: 1px solid white;

    .counter_container {
      //background: url("../../images/egame/egame_top_pic.png") no-repeat center 110px;
      //background-size: contain;
      width: 1183px;
      height: 480px;
      margin: 0 auto;
      overflow: hidden;
      position: relative;

      .banner_con {
        position: relative;
        top: 110px;
        width: 1183px;
        height: 480px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .counter {
        position: absolute;
        width: 400px;
        height: 247px;
        top: 190px;
        right: 130px;
        transform: scale(.9);

        .ele {
          background: url("../../images/egame/counter_bg.png") no-repeat;
          .getBgSrc("/egame/counter_bg.png", center 0px);
          position: absolute;
          bottom: 87px;
          left: 0;
          right: 0;
          margin: auto;
          width: 470px;
          height: 68px;
        }
      }
    }
  }

  .egame_lump {
    position: relative;
    width: 1200px;
    height: 50px;
    margin: 15px auto;
    margin-bottom: 6px;


    .egame_menu {
      display: inline-flex;

      .ele {
        width: 115px;
        height: 36px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #B6C5E9;
        line-height: 36px;
        cursor: pointer;
      }

      .active {
        color: #768CBE;
      }
    }

    .egame_btn {
      display: inline-block;
      height: auto;
      margin-left: -3px;

      .btn {
        display: inline-block;
        width: 128px;
        height: 48px;
        line-height: 46px;
        font-size: 16px;
        font-weight: bold;
        color: #757C94;
        text-align: center;
        background: url("../../images/egame/platformBg.png") no-repeat top center;
        background-size: cover;
        margin-right: 3px;
        cursor: pointer;
      }

      .btn_active {
        display: inline-block;
        width: 128px;
        height: 48px;
        line-height: 46px;
        font-size: 16px;
        font-weight: bold;
        color: white;
        text-align: center;
        background: url("../../images/egame/platformBgAct.png") no-repeat top center;
        background-size: cover;
        margin-right: 3px;
        cursor: pointer;
      }

      .search {
        position: absolute;
        top: 2px;
        right: 0;
        width: 299px;
        height: 38px;
        background: #FAFBFF;
        border: 1px solid #E0E5F6;
        border-radius: 4px;

        input {
          background: transparent;
          border: none;
          width: 260px;
          height: 38px;
          line-height: 38px;
          text-indent: 10px;
        }

        input::placeholder {
          font-size: 14px;
          color: #9DA5BB;
        }

        img {
          position: relative;
          top: -2px;
          left: 7px;
          cursor: pointer;
        }
      }
    }
  }

  .content {
    width: 1200px;
    height: 824px;
    background: #FFFFFF;
    box-shadow: 0px 11px 21px 0px rgba(45, 55, 110, 0.11);
    border-radius: 10px;
    margin: 0px auto;
    margin-bottom: 50px;

    .egame_con {
      width: 1159px;
      height: auto;
      margin: 22px auto;
      margin-top: 0;

      .egame_content {
        position: relative;
        width: 1159px;
        height: auto;
        padding-top: 22px;
        padding-bottom: 22px;

        .egame_page {
          margin-left: 40px;
          //margin-top: 40px;
          margin-bottom: 100px;
          height: 32px;
          overflow: hidden;

          ul {
            text-align: center;

            .pageindexbox {
              height: 28px;
              border: 1px solid #E0E5F6;
              width: 40px;
              text-align: center;
            }

            .page_btn_rowcount {
              display: inline-block;
              margin-right: 13px;
              width: 60px;
              height: 30px;
              line-height: 26px;
              text-align: center;
              color: #9DA5BB;
              border-radius: 3px;
              cursor: pointer;
            }

            .page_btn_pagesize {
              display: inline-block;
              margin-right: 13px;
              width: 60px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              color: #9DA5BB;
              border: 1px solid rgba(224, 229, 246, 1);
              border-radius: 3px;
              cursor: pointer;
            }

            .page_btn {
              display: inline-block;
              margin-right: 13px;
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              color: #9DA5BB;
              border: 1px solid rgba(224, 229, 246, 1);
              border-radius: 3px;
              cursor: pointer;

              &:hover {
                color: rgba(56, 195, 211, 1);
                border: 1px solid rgba(56, 195, 211, 1);
              }
            }

            .page_btn_active {
              display: inline-block;
              margin-right: 13px;
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              color: rgba(56, 195, 211, 1);
              //border: 1px solid rgba(56, 195, 211, 1);
              border-radius: 3px;
              background: rgba(81, 111, 177, 1);
              cursor: pointer;
              position: relative;
              top: 1px;

              font-size: 16px;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
        }

        .egame_ele {
          position: relative;
          width: 1159px;
          height: auto;
          display: grid;
          grid-template-columns: 216px 216px 216px 216px 216px;
          grid-column-gap: 20px;

          .empty {
            position: absolute;
            left: 0;
            right: 0;
            display: block;
            transform: translateY(100px);
            margin: auto;
            text-align: center;
            color: #bdbdbd;
            font-size: 18px;
            line-height: 60px;

            img {
              width: 200px;
              height: auto;
              display: block;
              margin: 0 auto;
              margin-top: 20%;
            }
          }

          .loading {
            position: absolute;
            text-align: center;
            left: 0;
            right: 0;
            top: 350px;
            margin: auto;
          }

          .ele {
            margin-bottom: 30px;
            position: relative;
            width: 216px;
            height: 159px;
            cursor: pointer;

            .ele_play {
              display: none;
              background: linear-gradient(-90deg, #345ED3, #7A9CF9);
              color: white;
              box-shadow: 0 0 6px 3px rgba(20, 183, 202, 0.2);
              width: 150px;
              height: 36px;
              border-radius: 5px;
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              line-height: 36px;
              position: absolute;
              top: 55px;
              left: 0;
              right: 0;
              margin: auto;
            }

            .egame_img {
              width: 216px;
              height: 130px;
              overflow: hidden;
              position: relative;

              img {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 216px;
                height: auto;
              }
            }

            .egame_des {
              width: 216px;
              height: auto;
              margin: 0 auto;
              display: grid;
              grid-template-columns: 196px 20px;

              .egame_name {
                font-size: 16px;
                font-weight: 400;
                color: #6A7391;
                margin-top: 7px;
              }

              .egame_collect {
                background: url("../../images/egame/collect.png");
                .getBgSrc("/egame/collect.png");
                background-size: cover;
                width: 20px;
                height: 19px;
                margin-top: 8px;
                box-shadow: none;
              }

              .egame_collect_active {
                background: url("../../images/egame/collected.png");
                .getBgSrc("/egame/collected.png");
                background-size: cover;
                width: 20px;
                height: 19px;
                margin-top: 8px;
              }
            }
          }

          .ele:hover {
            position: relative;

            .egame_img {
              width: 216px;
              height: 130px;
              box-shadow: #a0a0a0 0px 0px 8px;
              overflow: hidden;
              position: relative;
              transition: all .3s;
              transform: scale(1.1);

              img {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 216px;
                height: auto;
              }
            }
          }

          .ele_leg {
            margin-left: 30px;
            margin-bottom: 30px;
            position: relative;
            width: 250px;
            height: 255px;
            background: white;
            box-shadow: 0 0 3px 1px rgba(200, 200, 200, 0.27);
            border-radius: 5px;
            overflow: hidden;
            cursor: pointer;

            .ele_play {
              display: none;
              background: linear-gradient(-90deg, #345ED3, #7A9CF9);
              color: white;
              box-shadow: 0 0 6px 3px rgba(20, 183, 202, 0.2);
              width: 150px;
              height: 36px;
              border-radius: 5px;
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              line-height: 36px;
              position: absolute;
              top: 55px;
              left: 0;
              right: 0;
              margin: auto;
            }

            .egame_img {
              position: relative;
              top: 0px;
              left: 0px;
              display: block;
              width: 150px;
              height: 150px;
              margin: 0 auto;
              margin-top: 10px;
            }

            .egame_name {
              display: block;
              text-align: center;
              font-weight: 400;
              color: rgba(102, 102, 102, 1);
              font-size: 20px;
              color: #666;
              margin: 0 auto;
              margin-top: 13px;
            }

            .egame_btn_go {
              display: block;
              width: 85px;
              height: 28px;
              margin: 0 auto;
              margin-top: 5px;
              border-radius: 2px;
              line-height: 28px;
              color: #fff;
              text-align: center;
              background: #e5233f;
              font-size: 14px;

              &:hover {
                opacity: .8;
              }
            }
          }
        }
      }
    }
  }
}
