.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

.isShow {
  display: block;
}

.isHide {
  display: none;
}

.titleLine {
  position: relative;
  color: #3D3D3D;
  font-size: 16px;
  padding: 10px 0 10px 33px;
  font-weight: bold;
}

.withdrawal {

  .btn_refresh {
    position: relative;
    left: 15px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    opacity: .7;
    text-indent: 27px;
    color: #666666;
    width: 85px;
    height: 25px;
    line-height: 24px;
    border: 1px solid #EDEDEE;
    border-radius: 13px;
    margin-top: 15px;
    cursor: pointer;

    .refresh_btn_rotate {
      display: inline-block;
      position: relative;
      top: -11px;
      left: -20px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: auto;
      }
    }
  }

  .content_transfer {
    position: relative;
    //background: url("../../images/moneybag/bg_white.png") no-repeat;
    //.getBgSrc("/moneybag/bg_white.png");
    //background-size: contain;
    padding-top: 20px;

    .money_bag_lump {
      width: 686px;
      height: 95px;
      margin: 0px auto;
      margin-bottom: 10px;
      display: grid;
      grid-template-columns: auto auto auto;

      .moneybag_kind:last-child {
        margin-left: 18px;
      }

      .moneybag_kind:nth-child(2) {
        margin-left: 20px;
      }

      .moneybag_kind {
        overflow-y: hidden;
        position: relative;
        width: 215px;
        height: 95px;
        background: #FFFFFF;
        border: 1px solid #CFDAF8;
        border-radius: 5px;
        box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.05);

        .title {
          display: block;
          width: 185px;
          margin: 0px auto;
          font-size: 16px;
          font-weight: 500;
          color: #666666;
          position: unset !important;
          text-align: left;
        }

        i {
          display: block;
          width: 185px;
          line-height: 53px;
          margin: 0px auto;
          font-size: 24px;
          color: #333333;
          font-style: unset;
          border-top: 1px solid #EDEEF0;
        }

        .btn_return {
          position: absolute;
          right: 15px;
          bottom: 14px;
          text-align: center;
          color: white;
          font-size: 14px;
          font-weight: unset;
          opacity: .7;
          width: 46px;
          height: 25px;
          line-height: 25px;
          background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
          border-radius: 5px;
          cursor: pointer;

        }

        .btn_into {
          font-size: 24px;
          text-align: center;
          text-indent: 0px;
          color: #20C9DD;
          width: 200px;
          height: 42px;
          line-height: 0px;
          cursor: pointer;
          margin-top: 15px;
        }

        span {
          position: relative;
          display: inline-block;
          color: black;
          font-size: 25px;
          text-align: center;
          text-indent: 0px;
          border-right: 1px solid rgba(255, 255, 255, .15);
          height: 95px;
          line-height: 38px;
          top: 33px;
        }
      }
    }

    .withdraw_counter {
      font-size: 20px;
      color: #6c6c6c;
      text-align: left;
      text-indent: 70px;
      width: 900px;
      display: inline-block;
      margin-top: 20px;
      margin-left: -80px;
      transform: scale(.85);

      i {
        margin: auto 5px;
      }
    }

    .normal_free_lump {
      display: inline-block;
      margin-top: 40px;
      margin-bottom: 40px;
      font-size: 30px;
      text-indent: 60px;
      color: #6c6c6c;
    }

    .input_custom {
      position: relative;
      width: 610px;
      height: 60px;
      border-bottom: 2px solid rgba(255, 255, 255, .3);
      line-height: 60px;
      margin: 20px auto;
      padding-top: 7px;

      span {
        font-size: 30px;
        position: absolute;
        top: 13px;
        left: -2px;
      }

      input {
        position: relative;
        top: 0px;
        width: 410px;
        line-height: 44px;
        padding: 0;
        padding-left: 50px;
        background: transparent;
        border: none;
        border-radius: unset;
        box-shadow: none;
        font-size: 24px;

        &::placeholder {
          color: black !important;
          opacity: .8;
          font-size: 24px;
        }
      }
    }


    .money_ele {
      position: relative;
      width: 680px;
      height: auto;
      margin: 40px auto;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: auto auto auto;

      .money_custom {
        position: relative;
        width: 220px;
        height: 70px;

        input {
          color: #6c6c6c;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          border: 1px solid #ebebeb;
          border-radius: 10px;
          width: 150px;
          height: 70px;
          line-height: 70px;
        }

        i.input_des {
          color: #6c6c6c;
          text-align: center;
          font-size: 22px;
          line-height: 28px;
          position: relative;
          top: -60px;
          margin: 0 auto;
          width: 170px;
          left: -10px;
          transform: scale(.85);

          i {
            display: block;
            font-size: 18px;
            color: #9aa0ac;
          }
        }

        b {
          position: absolute;
          width: 100%;
          font-size: 24px;
          text-align: center;
          left: 0;
          right: 0;
          margin: auto;
          color: red;
          top: 180px;
        }
      }

      span {
        color: #6c6c6c;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        width: 220px;
        height: 70px;
        line-height: 70px;

        &.active {
          color: white;
          background: #20c9de;
          border: 1px solid #20c9de;
        }
      }
    }

    .moneybag_kind_ele {
      position: relative;
      width: 690px;
      height: auto;
      margin: 0 auto;
      //display: flex;
      //flex-direction: row;
      //flex-wrap: wrap;
      overflow: hidden;
      display: grid;
      grid-template-columns: auto auto auto auto auto auto;
      border-bottom: 1px solid #F8F8F8;
      padding-bottom: 25px;

      span {
        background: white;
        display: inline-block;
        position: relative;
        color: #666666;
        font-size: 14px;
        text-align: center;
        text-indent: 0px;
        border: 1px solid #EDEDEE;
        border-radius: 6px;
        width: 108px;
        height: 65px;
        line-height: 35px;
        padding-top: 5px;
        margin: 3px;
        margin-top: 14px;
        margin-bottom: 0px;
        flex: auto;

        i {
          position: relative;
          top: -10px;
          display: block;
          font-size: 16px;
          font-weight: 400;
          font-style: unset;
          color: #333333;
        }
      }
    }

    .moneybag_menus_lump {
      width: 100%;
      height: auto;
      background: white;

      .ele {
        border-bottom: 1px solid #ececec;
        margin: 10px auto;
        width: 650px;
        height: 105px;
        line-height: 60px;
        font-size: 26px;
        padding-left: 20px;
        padding-top: 25px;

        span {
          width: 60px;
          height: 60px;
          display: inline-block;
          margin-right: 40px;

          img {
            position: relative;
            top: 13px;
            width: 50px;
            height: auto;
          }
        }

        a {
          color: #666;
        }
      }
    }

    .deposit_pay_method {
      line-height: 60px;

      span {
        display: block;
        position: relative;
        text-align: left;
        font-size: 24px;
        font-weight: 400;
        line-height: 70px;
        width: 630px;
        height: 100px;
        margin-left: 45px;
        padding-left: 30px;
        border-bottom: 1px solid #ebebeb;

        .pay_method_img {
          width: 75px;
          height: 60px;
          position: relative;
          display: inline-block;
          margin: 0 auto;
          overflow: hidden;
          top: 20px;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 75px;
            height: auto;
          }
        }

        .pay_method_recommend {
          position: absolute;
          top: -28px;
          right: 29px;
          width: 31px;
          height: 23px;
        }

        .pay_method_name {
          display: inline-block;
          font-size: 28px;
          position: absolute;
          top: 30px;
          width: 190px;
          text-indent: 0px;
          text-align: left;
          line-height: 30px;
        }

        .pay_method_remark {
          display: inline-block;
          font-size: 20px;
          position: absolute;
          top: 17px;
          left: 300px;
          color: #9aa0ac;
          width: 300px;
          text-align: left;
          line-height: 30px;
        }
      }

      .active {
        color: #6ACEDA;
        //background: url('../../images/moneybag/icon_sure2.png') no-repeat;
        //.getBgSrc("/moneybag/icon_sure2.png", 600px 35px);
      }
    }
  }

  .chossBankUSDT {
    width: 100%;
    padding-left: 32px;

    li {
      display: none;
      display: block;
      cursor: pointer;
      border: 1px solid #D1DFE9;
      float: left;
      background: linear-gradient(0deg, #F8F8F8, #FFFFFF);
      .setW-H(108px, 35px);
      line-height: 35px;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      color: #666666;
    }

    li:nth-child(1) {
      border-radius: 5px 0px 0px 5px;
      border-right: none;
    }

    li:nth-child(2) {
      border-radius: 0px 5px 5px 0px;
      border-left: none;
    }

    .act {
      background: linear-gradient(0deg, #7A9CF9, #6387ED);
      border-radius: 0px 5px 5px 0px;
      font-size: 13px;
      font-weight: bold;
      color: #FFFFFF;
    }
  }

  .addUsdtAddra {
    float: left;
    //margin: 13px 0 0 0;
    background: url("../../images/usercenter/iconUsdtAdd.png") 89px 13px no-repeat;
    background-size: 25px 25px;
    text-indent: 122px;
    line-height: 50px;
    width: 339px;
    height: 50px;
    border: 1px dashed #D1DFE9;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    cursor: pointer;
  }

  .usdtList {
    margin-bottom: 10px;
    cursor: pointer;
    margin-right: 8px;
    position: relative;
    float: left;
    .setW-H(339px, 50px);
    background: url("../../images/usercenter/usdtBg.png") no-repeat;
    background-size: 339px 50px;

    .title1 {
      text-indent: 12px;
      font-size: 15px;
      font-weight: bold;
      color: #424D76;
      float: left;
      line-height: 50px;
    }

    .title2 {
      text-indent: 18px;
      font-size: 14px;
      font-weight: 400;
      color: #767F97;
      float: left;
      line-height: 50px;
    }

    .check {
      position: absolute;
      background: url("../../images/usercenter/usdtUnCheck.png") no-repeat;
      .setW-H(17px, 17px);
      background-size: 17px 17px;
      border-radius: 50%;
      top: 17px;
      right: 14px;
    }
  }

  .act {
    width: 339px;
    height: 50px;
    background: linear-gradient(90deg, #547BE4, #8FA7E7);
    box-shadow: 0px 7px 15px 0px rgba(94, 131, 228, 0.29);
    border-radius: 5px;


    .title1 {
      font-size: 15px;
      font-weight: bold;
      color: #FFFFFF;
      float: left;
      line-height: 50px;
    }

    .title2 {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      float: left;
      line-height: 50px;
    }

    .check {
      background: url("../../images/usercenter/usdtCheck.png") no-repeat;
      background-size: 17px 17px;
    }
  }

  .addUsdtPop, .addUsdtWithdrawal {
    .setW-H(460px, 436px);
    background: #fff;
    border-radius: 10px;
    z-index: 1002;
    overflow: hidden;
    position: fixed;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    .topTitle {
      position: relative;
      width: 460px;
      height: 46px;
      background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
      border-radius: 10px 10px 0px 0px;
      line-height: 46px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: #FFFEFE;

      .close {
        cursor: pointer;
        background: url("../../images/usercenter/usdtCloseBut.png") no-repeat top center;
        background-size: 15px 15px;
        position: absolute;
        .setW-H(15px, 15px);
        top: 15px;
        right: 17px;
      }
    }

    .redTitle {
      font-size: 16px;
      font-weight: bold;
      color: #CC3333;
      text-align: center;
      padding: 20px 0 0 0;
    }

    .subTitle {
      font-size: 14px;
      font-weight: bold;
      color: #8B9BAA;
      text-align: center;
      margin: 10px 0 0 0;
    }

    .textArea {
      line-height: initial;
      padding: 10px;
      display: block;
      margin: 20px auto 0 auto;
      width: 380px;
      height: 82px;
      background: #F7FBFE;
      border: 1px solid #D1DFE9;
      border-radius: 5px;
    }

    .buts {
      display: flex;
      justify-content: space-between;
      width: 60px;
      margin: 15px auto 0 auto;

      li {
        width: 60px;
        height: 33px;
        line-height: 33px;
        text-align: center;
        background: #FFFFFF;
        border: 1px solid #D1DFE9;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
      }
    }

    .remark {
      margin: 20px auto 0 auto;
      width: 378px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
    }

    .submit {
      margin: 18px auto 0 auto;
      width: 204px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: linear-gradient(90deg, #B1C5FA, #8EA4E7);
      border-radius: 5px;
      font-size: 15px;
      color: #FEFEFE;
      cursor: pointer;
    }
  }

  .addUsdtWithdrawal {
    .setW-H(460px, 356px);

    .submit {
      width: 204px;
      height: 40px;
    }

    .moneyLineRMB {
      text-align: center;
      padding-top: 35px;

      .title {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }

      span {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
      }
    }

    .moneyLineUSDT {
      text-align: center;
      border-bottom: 1px solid #B5C3D0;
      padding-top: 18px;
      padding-bottom: 38px;
      width: 380px;
      margin: auto;

      span {
        font-size: 24px;
        font-weight: bold;
        color: #CC3333;
      }

      .title {
        line-height: 25px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }

    .subTitleUsdtWith {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      text-align: center;
      margin-top: 20px;
    }

    .usdtAddraWith {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin-top: 20px;
    }
  }

  .moneybag_select_lump {
    position: relative;
    width: 686px;
    height: 80px;
    margin: 0 auto;
    font-size: 40px;
    font-weight: bold;
    color: #999999;

    .auto_transfer {
      margin-bottom: 15px;

      .normal_free_lump {
        width: auto;
        height: auto;
        padding: unset;
        background: unset;
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        color: #3D3D3D;
      }

      .btn_lump {
        position: absolute;
        top: 30px;
        left: 70px;
        display: block;
        width: 38px;
        height: 23px;
        background: unset;
        box-sizing: unset;
        cursor: pointer;

        .btn_normal {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 38px;
          height: 23px;
          background: unset;

          img {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 38px;
            height: 23px;
          }
        }

        .btn_free {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 38px;
          height: 23px;
          background: unset;

          img {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 38px;
            height: 23px;
          }
        }
      }
    }

  }

  .bankFrame {
    padding: 25px 0 25px 33px;
    border-bottom: 1px solid #F8F8F8;

    .bankBox {
      overflow: hidden;
      position: relative;
      cursor: pointer;
      .setW-H(215px, 95px);
      float: left;
      margin: 0 15px 15px 0;
      background: url("../../images/usercenter/bankBgMini.png") no-repeat center center;
      background-size: cover;
      border-radius: 5px;
      box-shadow: #c2c0c0 0px 3px 19px;

      .bankname {
        margin: 14px 0 0 18px;
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        width: 100%;
      }

      .bankNum {
        letter-spacing: 1px;
        color: rgba(255, 255, 255, 1);
        margin: 14px 0 0 18px;
        font-size: 13px;
        font-weight: bold;
      }

      .trueName {
        /*position: absolute;
        left: -96px;
        bottom: -63px;
        text-align: left;
        margin: 22px 0 0 18px;
        width: 100%;
        color: #ffffff;
        transform: scale(0.1, 0.1);
        font-size: 100px;*/

        position: absolute;
        bottom: 2px;
        text-align: left;
        margin: 22px 0 0 18px;
        width: 100px;
        color: #fff;
        font-size: 12px;
      }
    }

    .bankBoxAct {
      background: url("../../images/usercenter/bankbgActMini.png") no-repeat center center;
      background-size: cover;
      margin-right: 16px;
    }

    .bankBoxEmpty {
      cursor: pointer;
      .setW-H(215px, 95px);
      border: 1px dashed #D1DFE9;
      border-radius: 10px;
      float: left;

      span {
        display: block;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: #333333;

        img {
          width: 30px;
          height: 30px;
          display: block;
          margin: 18px auto;
          margin-top: 15px;
        }
      }
    }
  }

  .withdrawalFrom {
    .withdrawalFrom_input {
      padding-top: 25px;
      padding-bottom: 25px;
      padding-left: 33px;
      border-bottom: 1px solid #F8F8F8;
    }

    .title {
      font-size: 14px;
      float: left;
      line-height: 30px;
    }

    .remark {
      float: left;
      color: #CC3333;
      font-size: 12px;
      line-height: 36px;
      text-indent: 15px;
    }

    .inputBox {
      position: relative;
      float: left;

      input {
        text-indent: 10px;
        float: left;
        .setW-H(169px, 29px);
        line-height: 27px;
        padding: 0;
        background: transparent;
        border: 1px solid rgba(237, 237, 237, 1);
        border-radius: 5px;
        box-shadow: none;
        color: #000;
        text-align: left;
        //text-indent: 10px;
      }
    }

    .inputMsg {
      color: #000 !important;
      height: 32px;
      line-height: 32px;
      width: 300px;
      float: left;
      margin-left: 16px;
      font-size: 12px;
    }

    .inputErr {
      color: red;
    }

    .butSubmit {
      margin: 54px 0 30px 331px;
      cursor: pointer;
      border: none;
      border-radius: 5px;
      width: 108px;
      height: 30px;
      text-align: center;
      color: #fff;
      background: linear-gradient(-90deg, rgb(127, 159, 235), rgb(176, 196, 250));
    }
  }
}
