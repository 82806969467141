.c {
  clear: both;
}

.topBannerBox {
  overflow: hidden;
  height: 220px;
  width: 100%;

  .shadowLine {
    width: 100%;
    height: 20px;
    background: linear-gradient(0deg, rgba(212, 217, 245, 0), rgba(177, 183, 218, 0.15));
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
  }

  .content {
    position: relative;
    width: 1250px;
    height: auto;
    margin: auto;

    .fontBoxt {
      text-align: center;
      margin-left: 58px;
      float: left;

      .title {
        margin-top: 45px;
        font-size: 28px;
        font-weight: 400;
        color: #3E4660;
        background: linear-gradient(0deg, #3E4660 0%, #6A7391 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .title2 {
        font-size: 14px;
        font-weight: 400;
        color: #768CBE;
        padding: 6px 0 2px 0;
      }

      .num {
        color: rgba(22, 51, 94, 1);
        font-size: 60px;
        text-align: left;
        font-weight: bold;
        line-height: 90%;

        img {
          width: 152px;
          height: 50px;
        }

        span {
          font-size: 15px
        }
      }
    }

    .butBox1:hover,
    .butBox2:hover,
    .butBox3:hover,
    .butBox4:hover,
    .butBox5:hover {
      background: url("../../images/public/platformBoxBg.png") top repeat-x,
      url("../../images/public/platformBoxBorder.png") top left no-repeat,
      url("../../images/public/platformBoxBorder.png") top right no-repeat;
      background-size: 100% 100%, 1px 100%, 1px 100%;

      .butLink {
        font-size: 15px;
        font-weight: bold;
        color: #5F698D;
        background: url("../../images/public/platformButBg.png") no-repeat top center;
        background-size: cover;
      }
    }

    .butBox1,
    .butBox2,
    .butBox3,
    .butBox4,
    .butBox5 {
      cursor: pointer;

      img {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .butLink {
        text-align: center;
        margin: 22px auto 0 auto;
        width: 128px;
        height: 42px;
        line-height: 34px;
        text-align: center;
        top: 22px;

        font-size: 15px;
        font-weight: bold;
        color: #757C94;
      }
    }

    .butBox1 {
      margin-left: 24px;
      position: relative;
      float: left;
      height: 220px;
      width: 200px;

      .img1 {
        width: 200px;
        height: 142px;
        margin: auto;
        display: block;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .butBox2 {
      position: relative;
      float: left;
      height: 220px;
      width: 200px;

      .img2 {
        width: 200px;
        height: 144px;
        margin: auto;
        display: block;
      }
    }

    .butBox3 {
      position: relative;
      float: left;
      height: 220px;
      width: 200px;

      .img3 {
        width: 200px;
        height: 146px;
        margin: auto;
        display: block;
      }
    }

    .butBox4 {
      position: relative;
      float: left;
      height: 220px;
      width: 200px;

      .img4 {
        width: 200px;
        height: 148px;
        margin: auto;
        display: block;
      }
    }

    .butBox5 {
      position: relative;
      float: left;
      height: 220px;
      width: 200px;

      .img5 {
        width: 200px;
        height: 149px;
        margin: auto;
        display: block;
      }
    }
  }
}
