.loadIcon {
  animation-name: loadIcon;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-duration: .5s;
  animation-iteration-count: infinite;
}

@keyframes loadIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
