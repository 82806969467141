.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

ul {
  list-style: none;
}

.contain {
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  height: auto;
  //background-size: cover;

  .topLine {
    background: rgb(244, 246, 252);
    height: 98px;
  }

  .content {
    background: url("../../images/public/realeBg.jpg") no-repeat top center;
    background-size: cover;

    .shadowLine{
      width: 100%;
      height: 20px;
      background: linear-gradient(0deg, rgba(212, 217, 245, 0), rgba(177, 183, 218, 0.15));
      top: 99px;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
    }

    .con {
      position: relative;
      width: 1185px;
      height: 840px;
      margin: auto;
    }

    .banner {
      position: absolute;
      top: 140px;
      right: -117px;

      .banner_con{
        .setW-H(799px, 699px);
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .banner1 {
      position: absolute;
      top: 140px;
      right: -117px;

      .banner_con{
        .setW-H(799px, 699px);
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .banner2 {
      position: absolute;
      top: 110px;
      right: -117px;

      .banner_con{
        .setW-H(799px, 729px);
        img {
          width: 100%;
          height: auto;
        }
      }
    }


    .platform {
      top: 30px;
      left: -10px;
      position: absolute;
      float: right;
      padding: 0;

      li {
        cursor: pointer;
        float: left;
        .setW-H(128px, 50px);
        background: url("../../images/public/platformBg.png");
        background-size: cover;
        text-align: center;
        line-height: 44px;
        margin-left: 3px;
        font-size: 15px;
        font-weight: bold;
        color: #757C94;
      }

      .actli {
        background: url("../../images/public/platformBgAct.png");
        background-size: cover;
        color: #FFFFFF;
      }
    }

    .fontTitle {
      position: absolute;
      top: 233px;
      left: -7px;

      .fontTitle_con{
        .setW-H(251px, 76px);

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .fontTitle1 {
      position: absolute;
      top: 233px;
      left: -7px;

      .fontTitle_con{
        .setW-H(315px, 76px);

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .fontTitle2 {
      position: absolute;
      top: 233px;
      left: -7px;

      .fontTitle_con{
        .setW-H(249px, 76px);

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .fontContent {
      position: absolute;
      top: 343px;
      left: 0px;
      width: 588px;
      font-size: 14px;
      color: rgba(118, 140, 190, 1);
      line-height: 30px;
    }

    .butTZ {
      cursor: pointer;
      background: url("../../images/public/butTZ.png");
      background-size: cover;
      position: absolute;
      top: 455px;
      left: 0px;
      .setW-H(210px, 54px);

      img {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 18px;
        left: 15px;
      }
    }

    .butTZ:hover{
      background: url("../../images/public/butTZAct.png");
      background-size: cover;
    }
  }
}
