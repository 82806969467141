.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

ul {
  list-style: none;
}

.contain {
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  height: auto;

  .topLine {
    background: rgb(244, 246, 252);
    height: 98px;
  }

  .content {
    background: url("../../images/public/EsportsBg.png") no-repeat top center;
    background-size: cover;

    .shadowLine{
      width: 100%;
      height: 20px;
      background: linear-gradient(0deg, rgba(212, 217, 245, 0), rgba(177, 183, 218, 0.15));
      top: 99px;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
    }

    .con {
      position: relative;
      width: 1185px;
      height: 840px;
      margin: auto;
    }

    .banner {
      position: absolute;
      .setW-H(1699px, 718px);
      bottom: -35px;
      left: -236px;
      right: 0;

      img {
        width: 100%;
        height: auto;
      }
    }

    .titleImg {
      position: absolute;
      top: 153px;
      margin: auto;
      left: 0;
      right: 0;
      width: 271px;
      height: 76px;
      img {
        width: 271px;
        height: 76px;
      }
    }

    .txtmlte {
      position: absolute;
      text-align: center;
      margin: auto;
      left: 0;
      right: 0;
      top: 263px;
      width: 580px;
      font-size: 14px;
      font-weight: 400;
      color: #768CBE;
      line-height: 30px;
    }

    .nowTZ {
      cursor: pointer;
      background: url("../../images/public/butTZ.png") no-repeat center center;
      background-size: cover;
      .setW-H(210px, 54px);
      margin: auto;
      left: 0;
      right: 0;
      top: 378px;
      position: absolute;

      img {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 18px;
        left: 15px;
      }
    }

    .nowTZ:hover {
      background: url("../../images/public/butTZAct.png") no-repeat center center;
      background-size: cover;
    }
  }
}
