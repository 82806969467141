.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

ul {
  list-style: none;
}

.contain {
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  height: auto;
  background: rgb(229, 236, 241) !important;
  background-size: cover;

  .topLine {
    background: rgb(244, 246, 252);
    height: 98px;
  }

  .content {
    box-shadow: 0px 0px 20px rgba(136, 136, 136, 0.47);
    background: url("../../images/vip/bg.png") no-repeat -10px -10px #fff;
    background-size: 105% auto;
    position: relative;
    z-index: 99;
    .setW-H(1200px, auto);
    margin: 22px auto 25px auto;
    padding-bottom: 50px;
    overflow: hidden;
    border-radius: 10px;
  }

  .banner {
    position: relative;
    width: 100%;
    height: 502px;
    margin: 0 auto;

    .banner_con{
      position: relative;
      .setW-H(1920px, 502px);
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .cardList {
    .setW-H(575px, auto);
    display: flex;
    justify-content: space-between;
    margin: 48px 0 0 316px;

    li {
      .setW-H(169px, 60px);
      border-radius: 10px;
      border: 1px solid rgba(162, 175, 218, 1);
      font-size: 18px;
      line-height: 60px;
      text-align: center;
      cursor: pointer;
      color: rgba(197, 201, 224, 1);
    }

    .liactive {
      .setW-H(173px, 74px);
      color: #fff;
      background: url("../../images/vip/cardList.png") no-repeat;
      background-size: cover;
      border-radius: unset;
      border: none;
    }
  }

  .cardInfo {
    margin: 41px 0 0 333px;
    .setW-H(670px, 267px);
    //background: url("../../images/vip/cardBg.png") no-repeat;
    //background-size: cover;
    position: relative;
    color: #fff;

    .cardInfo_img{
      position: relative;
      .setW-H(670px, 267px);
      img {
        width: 100%;
        height: auto;
      }
    }

    div {
      position: absolute;
    }

    .leave {
      font-size: 16px;
      top: 13px;
      left: 25px;
    }

    .title {
      font-size: 50px;
      left: 48px;
      top: 58px;
    }

    .vipleave {
      font-size: 30px;
      left: 48px;
      top: 120px;
    }

    .cells {
      text-align: center;
      display: flex;
      width: 500px;
      top: 190px;
      //left: 0px;
      //justify-content: space-between;

      .cell {
        position: initial;
        font-size: 24px;
        flex: 1;
        font-weight: bold;

        span {
          display: block;
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }

  .tipLine {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0 0 0;

    span {
      font-weight: normal;
    }
  }

  .activeContent {
    width: 870px;
    margin: 65px auto 40px auto;

    span {
      font-weight: bold;
      padding-right: 10px;
    }
  }

  .gridTable {
    margin: auto;
    width: 1053px;

    .titleRow {
      height: 50px;
      line-height: 50px;
      background: rgba(139, 164, 223, 1);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      color: #fff;
      text-align: center;
      font-size: 18px;
    }

    .rowsTitle {
      font-size: 14px;
      text-align: center;
      color: rgba(34, 34, 34, 1);
      height: 50px;
      line-height: 50px;
      display: grid;
      grid-template-columns: 139px 222px 222px 222px auto;

      .cell {
        height: 50px;
        width: 100%;
      }

      .cell:nth-child(odd) {
        background: rgb(235, 236, 236);
      }

      .cell:nth-child(even) {
        background: rgb(243, 243, 243);
      }

    }

    .rows:nth-child(odd) {
      font-size: 12px;
      text-align: center;
      color: rgba(75, 75, 74, 1);
      height: 32px;
      line-height: 32px;
      display: grid;
      grid-template-columns: 139px 222px 222px 222px auto;

      .cell {
        height: 32px;
        width: 100%;
      }

      .cell:nth-child(odd) {
        background: rgb(247, 247, 247);
      }

      .cell:nth-child(even) {
        background: rgb(254, 255, 255);
      }
    }

    .rows:nth-child(even) {
      font-size: 12px;
      text-align: center;
      color: rgba(75, 75, 74, 1);
      height: 32px;
      line-height: 32px;
      display: grid;
      grid-template-columns: 139px 222px 222px 222px auto;

      .cell {
        height: 32px;
        width: 100%;
      }

      .cell:nth-child(odd) {
        background: rgb(235, 236, 236);
      }

      .cell:nth-child(even) {
        background: rgb(243, 243, 243);
      }
    }

    .gridFoot {
      height: 32px;
      background: rgb(178, 202, 255);
      text-align: center;
      line-height: 32px;
      cursor: pointer;
    }

    .gridTip {
      color: rgba(75, 75, 74, 1);
      margin: 10px 0 6px 10px;
      font-size: 12px;
    }
  }

  .gridRemark {
    width: 1053px;
    margin: auto;

    .remarkTitle {
      background: rgba(232, 239, 251, 1);
      font-size: 18px;
      color: rgba(37, 37, 37, 1);
      height: 32px;
      text-align: center;
      margin: 0 0 20px 0;
    }

    .remarkSubTitle {
      color: rgba(98, 148, 254, 1);
      font-size: 16px;
      text-indent: 7px;
    }

    .remarkSubTxt {
      color: rgba(75, 75, 74, 1);
      font-size: 12px;
      margin-bottom: 22px;
      text-indent: 7px;

      a{
        color: rgba(98, 148, 254, 1);
      }

      .cir {
        margin-top: 2px;
        float: left;
        text-indent: 0px !important;
        display: block;
        border-radius: 50%;
        .setW-H(15px, 15px);
        background: rgba(98, 148, 254, 1);
        color: #fff;
        line-height: 15px;
        text-align: center;
      }
    }
  }
}
