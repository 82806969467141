@import "public";

.leftMenu {
  position: absolute;
  width: 197px;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgb(224, 224, 224) 4px 0px 20px;
  overflow: hidden;

  .userInfo {
    height: 90px;
    position: relative;

    .headImg {
      display: block;
      .setW-H(50px, 50px);
      border-radius: 50%;
      position: absolute;
      top: 19px;
      left: 10px;
    }

    .userId {
      .setW-H(76px, 16px);
      color: #000;
      position: absolute;
      top: 18px;
      left: 68px;
      font-size: 16px;
    }

    /*.userDays {
      position: absolute;
      color: #b0b0b0;
      font-size: 12px;
      width: 118px;
      height: 10px;
      top: 55px;
      right: 17px;
    }*/

    .userLeave {
      position: absolute;
      background: url("../../images/usercenter/leaveBg.png") no-repeat top center;
      background-size: cover;
      width: 50px;
      height: 18px;
      border-radius: 55px;
      overflow: hidden;
      top: 44px;
      left: 68px;

      span {
        position: absolute;
        top: 0px;
        left: 18px;
        font-size: 12px;
        color: #fff;
      }
    }
  }

  .moneyInfo {
    .setW-H(184px, auto);
    border-bottom: 1px solid rgba(215, 215, 215, 1);
    border-top: 1px solid rgba(215, 215, 215, 1);
    margin: auto;

    .title {
      color: rgba(51, 51, 51, 1);
      font-size: 12px;
      text-align: center;
      margin: 10px 0 4px 0;
    }

    .total {
      display: block;
      font-size: 20px;
      font-weight: bold;
      text-indent: -5px;
      color: rgba(51, 51, 51, 1);
      text-align: center;
      padding-bottom: 5px;

      span {
        font-size: 14px;
      }
    }
  }

  .menuUl {
    list-style: none;
    width: 100%;
    padding-top: 30px;

    li {
      font-size: 14px;
      margin-bottom: 20px;
      position: relative;
      text-indent: 54px;
      color: rgba(35, 35, 35, 1);
      cursor: pointer;
      height: 36px;
      line-height: 36px;

      a {
        display: block;
        color: rgba(35, 35, 35, 1);
        width: 100%;
        height: 36px;
      }

      img {
        .setW-H(18px, 18px);
        position: absolute;
        top: 9px;
        left: 23px;
      }
    }

    .activiLi {
      border-right: 4px solid rgba(98, 148, 254, 1);
    }
  }
}
