body {
  font-family: Arial, 'Microsoft YaHei' !important;
}

input[type="text"] {
  outline: none;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}
