.setW-H(@W, @H) {
  width: @W;
  height: @H;
}

.cashLog {

  .cashTypeList {
    display: flex;
    width: 480px;
    list-style: none;
    justify-content: space-between;
    margin: 0 0 0 55px;

    li {
      cursor: pointer;
      position: relative;
      border-radius: 10px;
      border: 2px solid rgba(237, 237, 238, 1);
      .setW-H(80px, 95px);

      img {
        .setW-H(25px, auto);
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        margin: 15px auto 0 auto;
      }

      .payname {
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
        text-align: center;
        position: absolute;
        left: 0;
        top: 50px;
        width: 100%;
      }

      .paynameMid {
        line-height: 240%;
      }
    }

    .activeLi {
      border: 2px solid rgba(143, 167, 233, 1);
      background: url("../../images/usercenter/iconPayCheck.png") no-repeat bottom right;
      background-size: 16px 16px;
    }
  }

  .conLine {
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
    height: 30px;
    line-height: 30px;
    padding-left: 44px;

    .title {
      margin: 0 15px;
      font-size: 14px;
    }

    .but {
      .setW-H(66px, 30px);
      position: absolute;
      right: 80px;
      top: 0px;
      border-radius: 5px;
      width: 90px;
      height: 30px;
      text-indent: 0px;
      text-align: center;
      cursor: pointer;
      color: #fff;
      background: linear-gradient(-90deg, rgb(127, 159, 235), rgb(176, 196, 250));
    }
  }

  .dataEmpty {
    font-size: 14px;
    text-align: center;
    padding-top: 100px;
    img{
      margin:0 auto 15px auto;
      display: block;
      .setW-H(100px, 78px)
    }
  }
}
